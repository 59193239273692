.main-heading {
  h1 {
    color: $primary-color;
    font-family: "Roboto-Light";
    padding: 0px 0 10px;
    @media (max-width: 1366px) {
      font-size: 2.25rem;
    }
    span {
      font-style: italic;
      font-size: 3.125rem;
      font-weight: 600;
      @media (max-width: 1366px) {
        font-size: 2.8125rem;
      }
    }
  }
  h6,
  p {
    color: #666;
    padding: 0 0 12px 0;
    margin-bottom: 0;
    font-size: 1rem;
    font-family: "Poppins";
    font-weight: 400;
    @media (max-width: 1280px) {
      padding: 0 0 12px 0;
    }
  }
}
.btn-Login-Quickpay {
  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
  }
}
.login-content {
  padding: 0px 37px;
  @media (max-width: 576px) {
    padding: 0 24px;
  }

  &.registration-content {
    padding: 0px 24px;
  }
}
.registration-form-content {
  padding: 0px 24px;
}
.forgot-form-content {
  padding: 0 34px;
  @media (max-width: 768px) {
    padding: 0 18px;
  }
}
.security-content {
  padding: 0 !important;
}
.password-content {
  padding: 0 82px !important;
  @media (max-width: 576px) {
    padding: 0 34px !important;
  }
  .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 8.5px 14px;
  }
}
.security-image-content {
  padding: 0 45px 0 64px;
  @media (max-width: 576px) {
    padding: 0 30px !important;
  }
}
.more-info {
  margin-top: 15px;
  padding: 0;
  border-top: 1px solid #efefef;
  @media (max-width: 1280px) {
    margin-top: 0;
  }

  p {
    color: $lightblue;
    font-weight: 500;
    background-color: #fff;
    font-size: 17px;
    margin-bottom: 0;
    padding: 16px 0;
    border-radius: 0 0 1.875rem 1.875rem;
    @media (max-width: 767px) {
      font-size: 0.75rem;
      padding: 10px 12px;
    }
    @media (max-width: 1280px) {
      font-size: 0.8125rem;
      padding: 12px 0;
    }
  }
  a {
    color: $primary-color;
    text-decoration: underline;
    font-size: 1.0625rem;
    @media (max-width: 767px) {
      font-size: 0.75rem;
    }
    @media (max-width: 1280px) {
      font-size: 0.8125rem;
    }
  }
}

.admin-title {
  margin-bottom: 25px;
  font-size: 1.375rem;
  font-family: "poppins";
  font-weight: 600;
  text-align: center;
  @media (max-width: 576px) {
    font-size: 1.125rem;
  }
}

.admin-check-message {
  font-size: 1rem;
  color: #949494;
  font-weight: 400;
  font-family: "poppins" !important;
  @media (max-width: 576px) {
    font-size: 0.75rem;
  }
}

.admin-col {
  flex: auto;
  max-width: 100%;
}
.admin-light-blue-bg {
  background-color: $flash-white;
  border: 1px solid $light-orchid;
  padding: 30px 10px 30px 10px;
  border-radius: 4px;
  @media (max-width: 768px) {
    padding: 10px;
  }
}
.reset-password-text {
  margin-bottom: 10px;
  h6 {
    font-weight: 600;
  }
}
.admin-blue-title {
  color: $ocean-boat-blue;
  font-size: 18px;
  font-family: "poppins";
  font-weight: 600;
  display: flex;
  @media (max-width: 576px) {
    font-size: 16px;
  }
}

.new-admin-blue-title label {
  margin-bottom: 0;
}

.admin-character-password {
  text-decoration: none;
  list-style-type: none;
  padding: 0;
  margin-bottom: 0 !important;
  @media (max-width: 576px) {
    font-size: 11px;
  }
  li {
    color: $ocean-boat-blue !important;
    line-height: 30px;
    text-align: initial;
  }
}

.admin-close-icon {
  float: right;
}

.admin-close-icon-red {
  color: #dc3545 !important;
}

.admin-tick-icon-green {
  color: #28a745 !important;
}
.signin-check {
  &.Mui-checked {
    color: $ocean-boat-blue !important;
  }
}
@media screen and (max-width: 768px) {
  .admin-col {
    flex: auto;
    max-width: 100%;
  }
  .admin-light-blue-bg {
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .admin-light-blue-bg {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 480px) {
  .admin-light-blue-bg {
    padding: 15px;
  }
}

@media screen and (max-width: 375px) {
  .admin-light-blue-bg {
    padding: 15px;
  }
}

@media screen and (max-width: 360px) {
  .admin-light-blue-bg {
    padding: 15px;
  }
}

.security-check {
  p {
    padding-left: 12px;
  }
}
.accept-security-message {
  color: $red;
  p {
    margin: 0;
    font-size: 0.8125rem;
  }
}
.login-back-arrow {
  background: transparent;
  color: white;
  border: none;
  position: absolute;
  top: 25px;
  left: 10px;
}
.consent-checkbox {
  zoom: 1.25;
}
