@import "../themes/theme-mixin";

.sideBar {
  background-color: $white;
  .makeStyles-navMenuItem-53 {
    padding-bottom: 10px;
  }
}
.relativeUserIcon {
  position: relative;
  left: -3px;
}
.userIcon {
  padding-left: 0 !important;
  margin: 0 0 9px 18px;
  position: absolute;
}
.secondary-nav-sidebar {
  color: $true-blue !important;
  border-radius: 0 !important;
}

.secondary-nav-sidebar:hover {
  color: $white !important;
}

.theme-sidebar-btn {
  border-radius: 50% !important;
  min-width: 45px !important;
  min-height: 45px !important;

  &.blue-btn-sidebar {
    background-color: $true-blue !important;
  }
  &.red-btn-sidebar {
    background-color: $dark-shade-red !important;
  }
  &.green-btn-sidebar {
    background-color: $tahiti-gold !important;
  }
  &.skyblue-btn-sidebar {
    background-color: $azure !important;
  }
  &.lightBlue-btn-sidebar {
    background-color: #03dac5 !important;
  }
  &.darkBlue-btn-sidebar {
    background-color: $darkblue !important;
  }
  &.black-btn-sidebar {
    background-color: $black !important;
  }
  &.linear-blue-btn-sidebar {
    background: linear-gradient(#8e2de2 0%, #320097 100%);
  }
  &.linear-blue-btn-2-sidebar {
    background: linear-gradient(#03dac5 -18.96%, #6200ee 108.17%);
  }
  &.linear-red-btn-sidebar {
    background: linear-gradient(#eb4511 0%, #861c00 100%);
  }
  &.linear-lightblue-btn-sidebar {
    background: linear-gradient(#03ce97 5%, #00619a 90%);
  }
  &.linear-bluered-btn-sidebar {
    background: linear-gradient(#061161 0%, #c31432 100%);
  }
  &.linear-redblue-btn-sidebar {
    background: linear-gradient(#c33764 0%, #1d2671 100%);
  }
}
.theme-sidebar {
  display: flex;
  .main-btn-theme-sidebar {
    .btn-theme-sidebar {
      height: 32px;
      width: 50px;
      border-radius: 4px;
      margin-left: 13px;
      border: none;
      outline: none;
      background: linear-gradient(90deg, #1d2671 50%, #c33764 50%);
    }
  }
}

.sidenav {
  scroll-behavior: smooth;
  background-color: $white;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden !important;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 10px;
    @media (min-width: 1440px) {
      width: 0.5rem;
    }
  }
  // / Track /
  &::-webkit-scrollbar-track {
    border-radius: 0.625rem;
  }

  // / Handle /
  &::-webkit-scrollbar-thumb {
    background: $secondary-color;
    border-radius: 0.625rem;
  }
  ul {
    text-align: center;
    color: $black;
    .navListBtn {
      width: 100%;
      font-size: 1rem;
      cursor: pointer;

      @include theme-aware("color", "linear-2");

      .MuiSvgIcon-root {
        font-size: 1.5rem;
      }
      &:hover,
      &:active {
        @include linear-gradient-theme("background", 243deg, 0%, 100%, "linear-1", "linear-2");
        color: $white !important;
      }
      &:hover > .MuiListItemIcon-root {
        color: $white;
      }
      span {
        font-size: 0.875rem;
        font-weight: 400;
      }
    }
    .MuiCollapse-root .MuiListItemButton-root {
      @extend .navListBtn;
    }
    text-align: center;
    color: $black;
    li {
      width: 300px;
      list-style-type: none;
      padding: 10px 0 10px 15px;
      display: flex;
      align-items: center;
      font-size: 1rem;
      cursor: pointer;
      @include theme-aware("color", "linear-2");
      .userIcon {
        .MuiSvgIcon-root {
          font-size: 1rem;
        }
      }
      .MuiSvgIcon-root {
        font-size: 1.5rem;
      }
      span {
        padding-left: 20px;
        font-size: 0.875rem;
        font-weight: 600;
      }
      &:hover,
      &.active {
        @include linear-gradient-theme("background", 243deg, 0%, 100%, "linear-1", "linear-2");
        color: $white !important;
      }
      .subList {
        @include theme-aware("color", "linear-2");
      }
    }
    .active-state {
      @include linear-gradient-theme("background", 243deg, 0%, 100%, "linear-1", "linear-2");
      color: $white !important;
      .sublist-icon {
        color: $white !important;
      }
    }
  }
}
.sublist {
  @include theme-aware("color", "linear-2");
  white-space: nowrap;
}

.sublist-icon {
  @include theme-aware("color", "linear-2");
}
.ps__thumb-x,
.ps__thumb-y {
  background-color: $black !important;
}

.ps__rail-x {
  width: auto !important;
}

.ps__thumb-x {
  width: auto !important;
}

.form-style {
  padding: 10px 8px;
}

.sublist-align {
  .MuiListItemText-root {
    margin-left: 3px !important;
  }
}
.sublist-align2 {
  margin-left: 4px !important;
}
// sidenav-code
.sidenav {
  .MuiListItemIcon-root {
    min-width: 40px !important;
  }
  .css-ishquk-MuiButtonBase-root-MuiListItemButton-root {
    padding-left: 16px !important;
  }
  .MuiTypography-root {
    font-size: 0.875rem !important;
    font-weight: 400 !important;
  }
  .framework-list span {
    font-weight: 600 !important;
    white-space: nowrap;
  }
}
// sidenav framework subheading hover effects
.Cmt-miniLayout .Cmt-sidebar-content:hover .sublist {
  padding-left: 35px !important;
}
.Cmt-miniLayout .Cmt-sidebar-content:hover .ETsublist {
  padding-left: 6px !important;
  padding-right: 4px !important;
  .MuiSvgIcon-root {
    min-width: 50px !important;
  }
}

.Cmt-miniLayout .Cmt-sidebar-content:hover .event-sublist {
  padding-left: 55px !important;
}
// sidenav framework subheading hover effects
.event-sublist {
  .MuiListItemText-root {
    white-space: nowrap;
  }
}
@media (max-width: 1024px) {
  .sublist {
    padding-left: 35px !important;
  }
  .ETsublist {
    padding-left: 6px !important;
    padding-right: 4px !important;
    .MuiSvgIcon-root {
      min-width: 50px;
    }
  }
  .event-sublist {
    padding-left: 55px !important;
  }
}
@-moz-document url-prefix() {
  .sidenav ul li span {
    font-weight: 600;
    font-family: Roboto-regular !important;
  }
}
