$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semiBold: 600;
$font-bold: 700;
$card-sm: "450px";
$card-md: "640px";
// variables for text-colors

$app-primary-color: #6563fc;

//  Variables for background-colors
$app-primary-dark-theme: darken($app-primary-color, 10%);
$app-sidebar-theme: lighten($app-primary-color, 20%);
$app-secondary-dark-theme: #8b89fd;
$app-primary-deep-blue-theme: #1d2b4a;

// linear-gradients

$app-background-primary-color: darken($app-primary-dark-theme, 10%);
$app-background-secondary-color: lighten($app-secondary-dark-theme, 5%);

$app-color: blue;
$text-white: #ffffff;
$text-black: #000000;
$light-black-color: #202020;
$light-gray-color: #909090;
$background-color-light: #d6bbbb;
$light-card-color: #423212;

//global color
$dark-darken1: #6418c3;
$light-linear3: #696969;
$darkBlue-base: #5351fb;

//light theme

$light-background-color: #320469;
$light-linear1: #711671;
$light-linear2: #320469;

$light-lighten1: #32046926;

//dark theme

$darken-background: #191548;
$darken-card: #444343;
$darken-linear1: #00a1ab;
$darken-linear2: #191548;
$darken-lighten: #c4e3e6;

//yellow theme
$dark-black: #000;
$yellow-background: #227ba2;
$yellow-linear1: #005d98;
$yellow-linear2: #227ba2;
$yellow-secondary: #11e011;

//green theme
$green-linear1: #3470ae;
$green-linear2: #493d5d;
$green-lighten1: #366ca85c;

//red theme
// $red-darken1: #6418c3;
$red-background: #274edd;
$red-linear1: #412323;
$red-linear2: #274edd;
$red-lighten1: #3a2f5554;

// draken blue theme
$darkBlue-background: #1e2a78;
$darkBlue-lighten2: #7c7afc;
$darkBlue-lighten1: #eedcdf;
$darkBlue-accent2: #6664fb;
$darkBlue-accent3: #5070e7;
$darkBlue-accent4: #6765fc;
$darkBlue-linear1: #e24a61;

//blue grey theme
$blueGrey-background: #040353;
$blueGrey-linear1: #5855b8;
$blueGrey-lighten1: #525ab44a;

//dark theme
$dark-background: #37474f;
$dark-card: #212121;
