@import "../mixins/mixins";
.admin-piechart {
  width: 380px;
  height: 310px;
  margin: 0 auto;
  @media (max-width: 767px) {
    width: 250px;
    height: 250px;
  }
  .apexcharts-legend {
    max-width: 220px !important;
    justify-content: flex-start !important;
    white-space: nowrap !important;
  }
}
.survey-feedback-comment {
  margin: 5px 0px;
}
.survey-feedback {
  .survey-feedback-details {
    display: flex;
    @media (max-width: 576px) {
      justify-content: space-between;
    }
    .label-name {
      font-weight: $font-semiBold;
      margin-right: 8px;
      white-space: nowrap;
    }
    .label-value {
      color: $secondary-color;
      word-break: break-all;
    }
    .value {
      margin-left: 4px;
    }
  }
}
.survey-commentsColumn {
  max-width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;
}
