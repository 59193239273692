@import "../utils/variables";

$themes: (
  light: (
    color-background: $background-color-light,
    color-card: $text-white,
    card-color: $light-card-color,
    linear-gradient: (
      #8b89fd 0%,
      #5351fb 100%,
    ),
  ),
  dark: (
    color-background: $dark-background,
    color-card: $dark-card,
  ),
  lightTheme: (

    color-background: $light-background-color,
    font-color: $text-white,
    linear-1:   $light-linear1,
    linear-2:   $light-linear2,
    theme-3:    $light-linear3,
    lighten-1:  $light-lighten1,
    // color-background: #5351fb,
    // base: #5351fb,
    // darken-1: #6418c3,
    // darken-2: #5351fb,
    // lighten-3: #8b89fd,
    // lighten-2: #7c7afc,
    // lighten-1: #63637a,
    // accent-1: #6563fc,
    // accent-2: #6664fb,
    // accent-3: #5070e7,
    // accent-4: #6765fc,
    // linear-1: #8b89fd,
    // linear-2: #5351fb,
    // theme-3: $light-linear3,
    // font-color: $text-white,
  ),
  darkTheme: (
    darken-1: $dark-darken1,
    color-background: $darken-background,
    color-card: $darken-card,
    font-color: $text-white,
    // linear-1: #212121,
    // linear-2: #212121,
    linear-1: $darken-linear1,
    linear-2: $darken-linear2,
    lighten-1: $darken-lighten,
    secondary: $text-white,
    theme-3:  $light-linear3,
  ),
  yellowTheme: (
    darken-1: $dark-black,
    color-background: $yellow-background,
    font-color: $text-white,
    // linear-1: #ecf1a3,
    // linear-2: #eafb51,
    linear-1: $yellow-linear1,
    linear-2: $yellow-linear2,
    secondary: $yellow-secondary,
    theme-3: $light-linear3,
  ),
  greenTheme: (
    darken-1: $dark-black,
    color-background: #493D5D,
    font-color: $text-white,
    // linear-1: #9afc91,
    // linear-2: #73fb51,
    linear-1: $green-linear1,
    linear-2: $green-linear2,
    secondary: $text-white,
    theme-3: $light-linear3,
    lighten-1: $green-lighten1,
  ),
  redTheme: (
    darken-1: $dark-darken1,
    color-background: $red-background,
    font-color: $text-white,
    // linear-1: #f7aca2,
    // linear-2: #fb5151,
    linear-1: $red-linear1,
    linear-2: $red-linear2,
    secondary: $text-white,
    theme-3: $light-linear3,
    lighten-1: $red-lighten1,
  ),
  darkenBlueTheme: (
    // color-background: #320469,
    // font-color: $text-white,
    // linear-1:   #711671,
    // linear-2: #320469,
    // theme-3: $light-linear3,
    color-background: $darkBlue-background,
    base: $darkBlue-base,
    darken-1: $dark-darken1,
    darken-2: $darkBlue-base,
    lighten-3: $app-secondary-dark-theme,
    lighten-2: $darkBlue-lighten2,
    lighten-1: $darkBlue-lighten1,
    accent-1: $app-primary-color,
    accent-2: $darkBlue-accent2,
    accent-3: $darkBlue-accent3,
    accent-4: $darkBlue-accent4,
    linear-1: $darkBlue-linear1,
    linear-2: $darkBlue-background,
    theme-3: $light-linear3,
    font-color: $text-white,
  ),
  blueGreyTheme: (
    color-background: $blueGrey-background,
    font-color: $text-white,
    linear-1: $blueGrey-linear1,
    linear-2: $blueGrey-background,
    theme-3: $light-linear3,
    lighten-1:$blueGrey-lighten1,
  ),
);

$themeRadius: (
  smallRadius: (
    radius: 0.625rem,
  ),
  mediumRadius: (
    radius: 0.9375rem,
  ),
  largeRadius: (
    radius: 1.25rem,
  ),
);

$themeSideBar: (
  blueSideBarTheme: (
    sidebar: #6200ee,
    secondary: $text-white,
  ),
  lightBlueSideBarTheme: (
    sidebar: #03dac5,
    secondary: $text-white,
  ),
  darkBlueSideBarTheme: (
    sidebar: #23036a,
    secondary: $text-white,
  ),
  skyBlueSideBarTheme: (
    sidebar: #369fff,
    secondary: $text-white,
  ),
  redSideBarTheme: (
    sidebar: #b94343,
    secondary: $text-white,
  ),
  greenSideBarTheme: (
    sidebar: #2ba444,
    secondary: $text-white,
  ),
  linearBlueSideBarTheme: (
    sidebar: linear-gradient(#8e2de2 0%, #320097 100%),
    secondary: $text-white,
  ),
  linearBlue2SideBarTheme: (
    sidebar: linear-gradient(#03dac5 -18.96%, #6200ee 108.17%),
    secondary: $text-white,
  ),
  linearRedSideBarTheme: (
    sidebar: linear-gradient(#eb4511 0%, #861c00 100%),
    secondary: $text-white,
  ),
  linearLightBlueSideBarTheme: (
    sidebar: linear-gradient(#03ce97 5%, #00619a 90%),
    secondary: $text-white,
  ),
  linearBlueRedSideBarTheme: (
    sidebar: linear-gradient(#061161 0%, #c31432 100%),
    secondary: $text-white,
  ),
  linearRedBlueSideBarTheme: (
    sidebar: linear-gradient(#c33764 0%, #1d2671 100%),
    secondary: $text-white,
  ),
);

/**
 * theme-aware - Change color of a css property based on the currently active theme
 *
 * @param  {key}     CSS property
 * @param  {color}   Color name defined in the themes under _color-palette.scss
 *
 * @example - @include theme-aware('background', 'color-background');
 * @returns - background: $text-white;
 */
@mixin theme-aware($key, $color) {
  @each $theme-name, $theme-color in $themes {
    .theme-#{$theme-name} & {
      #{$key}: map-get(map-get($themes, $theme-name), $color);
    }
  }
}
@mixin btn-theme-aware($key1, $color1, $key2, $color2) {
  @each $theme-name, $theme-color in $themes {
    .theme-#{$theme-name} & {
      #{$key1}: map-get(map-get($themes, $theme-name), $color1);
      #{$key2}: map-get(map-get($themes, $theme-name), $color2);
    }
  }
}

//  background:  linear-gradient(243deg, #8b89fd 0%, #5351fb 100%);

@mixin linear-gradient-theme($key, $deg, $shade1, $shade2, $color1, $color2) {
  @each $theme-name, $theme-color in $themes {
    .theme-#{$theme-name} & {
      #{$key}: linear-gradient(
        $deg,
        map-get(map-get($themes, $theme-name), $color1) $shade1,
        map-get(map-get($themes, $theme-name), $color2) $shade2
      );
    }
  }
}

@mixin button-theme($key,  $bg-color, $color) {
  @each $theme-name, $theme-color in $themes {
    .theme-#{$theme-name} & {
      // #{$key}: linear-gradient(
      //   $deg,
      //   map-get(map-get($themes, $theme-name), $color1) $shade1,
      //   map-get(map-get($themes, $theme-name), $color2) $shade2
      // );
      #{$key}: ($width, $style, map-get(map-get($themes, $theme-name), $color));
    }
  }
}

@mixin sidebar-theme-aware($key, $color) {
  @each $theme-name, $theme-color in $themeSideBar {
    .sidebar-#{$theme-name} & {
      #{$key}: map-get(map-get($themeSideBar, $theme-name), $color);
    }
  }
}

@mixin border-theme($key, $color) {
  @each $theme-name, $theme-color in $themes {
    .theme-#{$theme-name} & {
      #{$key}: map-get(map-get($themes, $theme-name), $color);
    }
  }
}

@mixin theme-border-radius($key, $radius) {
  @each $radius-name, $theme-radius in $themeRadius {
    .radius-#{$radius-name} & {
      #{$key}: map-get(map-get($themeRadius, $radius-name), $radius);
    }
  }
}

@mixin set-primary-color($color) {
  @each $theme-color in $themes {
    $app-color: $color;
  }
}
