$primary-color: #644ad2;
$secondary-color: #adadad;
$skylighten-blue: #dddfff;
$lightblue: #536777;
$darkblue: #03014c;
$darkenblue: #39378d;
$oxfordblue: #070a28;
$white: #ffffff;
$white-cultured: #f5f5f5;
$white-silver: #ededed;
$silver: #f1eeee;
$darkensilver: #575757;
$darkgrey: #707070;
$grey: #8d8d8d;
$grey-web: #808080;
$black: #000000;
$lighten-black: #353333;
$green: #656d04;
$yellow-orange: #fc9519;
$darken-indigo: #144167;
$gray-silver-shade: #c1c1c19c;
$gray-silver: #c1c1c1;
$brown: #856404;
$white-brown: #ffeeba;
$azure: #007bff;
$azure-light: #007bff29;
$true-blue: #086bd6;
$light-orchid: #efebff;
$shade-gray: #b9b9b9;
$dark-charcoal: #333333;
$cool-grey: #495057;
$cornell-red: #ad1010;
$grimmy-grey: #777777;
$dark-orange: #663c00;
$light-yellow: #ffc107;
$light-purple: #8e7ba352;
$flash-white: #f2f4f7;
$persian-blue: #070a28;
$warm-white: #f1f1f1;
$red: #ff0000;
$moderate-blue: #3f51b5;
$platinum-gray: #797979;
$dark-shade-red: #a80000;
$gray: #666666;
$blue-magneta: #b2b2c8;
$tahiti-gold: #45db2e;
$very-light-gray: #e6e6e6;
$ocean-boat-blue: #0f75bc;
$warm-gray: #fafafa;
$milky-gray: #e0e0e0;
$concrete-gray: #f3f3f3;
$gray-purple: #8367f9;
$tan-green: #0895a4;
$carolina-blue: #559dcf;
$light-gray: #d5d5d5;
$azure-white: #d8eaf7;
$metallic-silver: #c0c5cb;
