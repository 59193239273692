$themes: (
  light: (
    color-background: #d6bbbb,
    color-card: #fff,
    card-color: #423212,
    linear-gradient: (
      #8b89fd 0%,
      #5351fb 100%,
    ),
  ),
  dark: (
    color-background: #37474f,
    color-card: #212121,
  ),
  lightTheme: (
    darken-1: #000,
    color-background: #320469,
    font-color: #ffffff,
    linear-1: #711671,
    linear-2: #320469,
    theme-3: #696969,
    lighten-1: #32046926,
    // color-background: #5351fb,
    // base: #5351fb,
    // darken-1: #6418c3,
    // darken-2: #5351fb,
    // lighten-3: #8b89fd,
    // lighten-2: #7c7afc,
    // lighten-1: #63637a,
    // accent-1: #6563fc,
    // accent-2: #6664fb,
    // accent-3: #5070e7,
    // accent-4: #6765fc,
    // linear-1: #8b89fd,
    // linear-2: #5351fb,
    // theme-3: #696969,
    // font-color: #fff,,,
  ),
  darkTheme: (
    darken-1: #000,
    color-background: #191548,
    color-card: #444343,
    font-color: #ffffff,
    // linear-1: #212121,
    // linear-2: #212121,
    linear-1: #00a1ab,
    linear-2: #191548,
    lighten-1: #019ba71c,
    secondary: #fff,
    theme-3: #696969,
  ),
  yellowTheme: (
    darken-1: #000,
    color-background: #227ba2,
    font-color: #ffffff,
    // linear-1: #ecf1a3,
    // linear-2: #eafb51,
    linear-1: #005d98,
    linear-2: #227ba2,
    secondary: #11e011,
    theme-3: #696969,
  ),
  greenTheme: (
    darken-1: #000,
    color-background: #493d5d,
    font-color: #ffffff,
    // linear-1: #9afc91,
    // linear-2: #73fb51,
    linear-1: #3470ae,
    linear-2: #493d5d,
    secondary: #fff,
    theme-3: #696969,
    lighten-1: #356eaa45,
  ),
  redTheme: (
    darken-1: #000,
    color-background: #274edd,
    font-color: #ffffff,
    // linear-1: #f7aca2,
    // linear-2: #fb5151,
    linear-1: #412323,
    linear-2: #274edd,
    lighten-1: #274edd33,
    secondary: #fff,
    theme-3: #696969,
  ),
  darkenBlueTheme: (
    color-background: #1e2a78,
    base: #5351fb,
    darken-1: #000,
    darken-2: #5351fb,
    lighten-3: #8b89fd,
    lighten-2: #7c7afc,
    lighten-1: #1f2a7836,
    accent-1: #6563fc,
    accent-2: #6664fb,
    accent-3: #5070e7,
    accent-4: #6765fc,
    linear-1: #e24a61,
    linear-2: #1e2a78,
    theme-3: #696969,
    font-color: #fff,
  ),
  blueGreyTheme: (
    color-background: #040353,
    font-color: #ffffff,
    linear-1: #5855b8,
    linear-2: #040353,
    theme-3: #696969,
    lighten-1: #5552b43d,
    darken-1: #000,
  ),
);

$themeRadius: (
  smallRadius: (
    radius: 0.625rem,
  ),
  mediumRadius: (
    radius: 0.9375rem,
  ),
  largeRadius: (
    radius: 1.25rem,
  ),
);

$themeSideBar: (
  blueSideBarTheme: (
    sidebar: #6200ee,
    secondary: #fff,
  ),
  lightBlueSideBarTheme: (
    sidebar: #03dac5,
    secondary: #fff,
  ),
  darkBlueSideBarTheme: (
    sidebar: #23036a,
    secondary: #fff,
  ),
  skyBlueSideBarTheme: (
    sidebar: #369fff,
    secondary: #fff,
  ),
  redSideBarTheme: (
    sidebar: #b94343,
    secondary: #fff,
  ),
  greenSideBarTheme: (
    sidebar: #2ba444,
    secondary: #fff,
  ),
  linearBlueSideBarTheme: (
    sidebar: linear-gradient(#8e2de2 0%, #320097 100%),
    secondary: #fff,
  ),
  linearBlue2SideBarTheme: (
    sidebar: linear-gradient(#03dac5 -18.96%, #6200ee 108.17%),
    secondary: #fff,
  ),
  linearRedSideBarTheme: (
    sidebar: linear-gradient(#eb4511 0%, #861c00 100%),
    secondary: #fff,
  ),
  linearLightBlueSideBarTheme: (
    sidebar: linear-gradient(#03ce97 5%, #00619a 90%),
    secondary: #fff,
  ),
  linearBlueRedSideBarTheme: (
    sidebar: linear-gradient(#061161 0%, #c31432 100%),
    secondary: #fff,
  ),
  linearRedBlueSideBarTheme: (
    sidebar: linear-gradient(#c33764 0%, #1d2671 100%),
    secondary: #fff,
  ),
);

/**
 * theme-aware - Change color of a css property based on the currently active theme
 *
 * @param  {key}     CSS property
 * @param  {color}   Color name defined in the themes under _color-palette.scss
 *
 * @example - @include theme-aware('background', 'color-background');
 * @returns - background: #FFF;
 */
@mixin theme-aware($key, $color) {
  @each $theme-name, $theme-color in $themes {
    .theme-#{$theme-name} & {
      #{$key}: map-get(map-get($themes, $theme-name), $color);
    }
  }
}
@mixin btn-theme-aware($key1, $color1, $key2, $color2) {
  @each $theme-name, $theme-color in $themes {
    .theme-#{$theme-name} & {
      #{$key1}: map-get(map-get($themes, $theme-name), $color1);
      #{$key2}: map-get(map-get($themes, $theme-name), $color2);
    }
  }
}

//  background:  linear-gradient(243deg, #8b89fd 0%, #5351fb 100%);

@mixin linear-gradient-theme($key, $deg, $shade1, $shade2, $color1, $color2) {
  @each $theme-name, $theme-color in $themes {
    .theme-#{$theme-name} & {
      #{$key}: linear-gradient(
        $deg,
        map-get(map-get($themes, $theme-name), $color1) $shade1,
        map-get(map-get($themes, $theme-name), $color2) $shade2
      );
    }
  }
}

@mixin button-theme($key, $bg-color, $color) {
  @each $theme-name, $theme-color in $themes {
    .theme-#{$theme-name} & {
      // #{$key}: linear-gradient(
      //   $deg,
      //   map-get(map-get($themes, $theme-name), $color1) $shade1,
      //   map-get(map-get($themes, $theme-name), $color2) $shade2
      // );
      #{$key}: ($width, $style, map-get(map-get($themes, $theme-name), $color));
    }
  }
}

@mixin sidebar-theme-aware($key, $color) {
  @each $theme-name, $theme-color in $themeSideBar {
    .sidebar-#{$theme-name} & {
      #{$key}: map-get(map-get($themeSideBar, $theme-name), $color);
    }
  }
}

@mixin border-theme($key, $width, $style, $color) {
  @each $theme-name, $theme-color in $themes {
    .theme-#{$theme-name} & {
      #{$key}: ($width, $style, map-get(map-get($themes, $theme-name), $color));
    }
  }
}

@mixin theme-border-radius($key, $radius) {
  @each $radius-name, $theme-radius in $themeRadius {
    .radius-#{$radius-name} & {
      #{$key}: map-get(map-get($themeRadius, $radius-name), $radius);
    }
  }
}

@mixin set-primary-color($color) {
  @each $theme-color in $themes {
    $app-color: $color;
  }
}
