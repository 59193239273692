.settlement-payment-content {
  .listed-description {
    border: 1px solid #388bfd66;
    padding: 2px 5px;
    border-radius: 8px;
    background-color: aliceblue;
    h5 {
      text-align: left;
      font-size: 1rem;
      font-weight: $font-medium;
      color: $primary-color;
      border-bottom: none;
      margin-bottom: 10px;
      @media (max-width: 768px) {
        font-size: 0.875rem;
      }

      @media (max-width: 576px) {
        font-size: 0.813rem;
      }
    }
  }
  .settlement-payment-header {
    @include theme-aware("background", "lighten-1");
    width: 100%;
    padding: 10px;
    margin: 15px 0 !important;
    border-radius: 8px;
  }
  .settlement-heading-title {
    td {
      font-size: 0.9rem;
      text-align: left;
      padding: 5px;
      font-weight: 500;
      font-family: "poppins";

      @media (max-width: 600px) {
        min-width: 70px;
      }
      &:nth-child(1) {
        width: 30%;
      }
      &:nth-child(2) {
        width: 20%;
        text-align: right;
        @media (max-width: 992px) {
          width: 33%;
        }
      }
      &:nth-child(3) {
        text-align: right;
      }
    }
  }
  .accordion-section {
    .css-1ex1afd-MuiTableCell-root {
      border-bottom: none;
      font-family: "poppins";
    }
    .accordion-header {
      background-color: #f3f3f3;
      border-radius: 8px 0px 0px 0px;
      padding: 0px 12px !important;
      min-height: 50px;
      td {
        font-size: 0.9rem;
        text-align: left;
        padding: 5px 5px;
        @media (max-width: 600px) {
          min-width: 70px;
          text-align: left;
          overflow-x: scroll;
        }
      }
      &.Mui-expanded {
        width: 100%;
        justify-content: flex-start;
        border-radius: 8px 8px 0px 0px;
        overflow: auto;
        overflow-y: hidden;
        min-height: 50px;
      }
      .MuiAccordionSummary-content {
        font-size: 1rem;
        font-weight: 600;
        color: #070a28;
        margin: 0px;
        justify-content: space-between;
        align-items: center;
        p {
          margin: 0px 8px;

          &:nth-child(2) {
            text-align: right;
            margin-right: 35px;
            @media (max-width: 1023px) {
              width: 30%;
              margin: 0px 8px;
            }
          }
        }
        .settle-button {
          background-color: $light-yellow !important;
          padding: 8px 32px !important;
          color: $black !important;
          border-radius: 5px;
          border: 0 !important;
          outline: 0 !important;
          .MuiButton-label {
            font-size: 0.875rem !important;
            @media (max-width: 767px) {
              font-size: 0.75rem !important;
            }
          }
          @media (max-width: 767px) {
            padding: 8px 20px !important;
          }
        }
      }
      .payment-options-button {
        @media (max-width: 600px) {
          flex: 1 1 190px;
        }
      }
      &.settlement-accordion-header {
        p {
          &:nth-child(2) {
            @media (max-width: 1023px) {
              width: auto;
              margin: 0px 8px;
            }
          }
        }
      }
    }
  }
  .settlement-table {
    td {
      padding: 5px;
      @media (min-width: 1536px) {
        font-size: 1.2rem;
      }
      @media (min-width: 992px) {
        font-size: 1rem;
      }
      &:nth-child(1) {
        width: 30%;
      }
      &:nth-child(2) {
        width: 20%;
        text-align: right;
        @media (max-width: 992px) {
          width: 33%;
        }
      }
    }
  }
  .proposed-helper-text {
    font-weight: 500 !important;
  }
  .proposed-input {
    padding: 5px 0;
    input {
      @media (max-width: 480px) {
        min-width: 100px;
      }
    }
  }
  .settlement-info-proposed {
    .error-text {
      font-size: 0.75rem;
      color: $red;
      padding-top: 5px;
    }
  }
  textarea,
  input[type="number"] {
    border-radius: 8px;
    padding: 8.5px 14px !important;
    font-size: 0.875rem !important;
  }
}

.rp-settlement-dialog {
  .balance-card {
    .amount-color {
      color: #ffa500;
      font-weight: 600;
      text-transform: capitalize;
    }
    p:nth-child(1) {
      margin-bottom: 5px;
    }
    p:nth-child(2) {
      margin-bottom: 15px;
    }
  }
  .account-color {
    color: $light-background-color;
    font-weight: 600;
    text-transform: capitalize;
  }
}
.amount-settled {
  color: $light-background-color;
  font-weight: 600;
  font-size: 1.375rem;
  white-space: pre;
}
