.amount1 {
  font-size: 1.375rem;
  font-weight: $font-semiBold;
}
.payment-portal-login {
  .payment-content {
    background-color: $milky-gray;
    border-radius: 8px;
    outline: none;
    padding: 10px 20px;
    position: sticky;
    z-index: 9;
    top: 58px;
    margin-bottom: 20px;
    box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;

    @media (max-width: 1024px) {
      padding: 10px 15px;
    }

    @media (max-width: 768px) {
      position: unset;
      padding: 5px 15px 15px;
    }
    @media (max-width: 600px) {
      padding: 15px;
    }

    .MuiInput-underline:before {
      border-bottom: 0 !important;
    }

    .MuiInput-underline:after {
      border-bottom: 0 !important;
    }

    .MuiFormControl-root {
      width: 100%;
      border-radius: 6px;
      margin: 0;
    }

    select {
      border: none;
      font-size: 0.875rem;
      border-radius: 4px;
      color: $darken-indigo;
    }

    .input-group-text {
      background-color: $white;
      margin: 10px 0;
      border-radius: 8px;
      padding: 2px;
      outline: none;
      border: 1px solid $shade-gray;

      .input-fields {
        display: flex;
        flex-direction: column;
        text-align: left;

        .para {
          font-size: 0.8125rem;
          color: $dark-charcoal;
          font-weight: $font-medium;
          margin-bottom: 0;

          @media (max-width: 1023px) {
            white-space: pre-wrap;
          }
        }

        .num {
          font-size: 0.8125rem;
          font-weight: $font-bold;
        }

        .MuiInputAdornment-positionStart {
          p {
            font-size: 1rem !important;
            font-weight: $font-bold !important;
            color: $cool-grey;
          }
        }

        .MuiInputBase-input {
          font-size: 0.938rem !important;
          font-weight: $font-bold !important;
          color: $cool-grey;
          padding: 0;
        }

        .css-ocgva1-MuiInputBase-root-MuiOutlinedInput-root {
          font-family: inherit;
          border: transparent !important;
          padding-left: 0;
        }
      }
    }

    .para {
      font-size: 1rem;
      color: $dark-charcoal;
      font-weight: $font-semiBold;
      white-space: nowrap;
      // margin-right: 2.5rem;
    }

    .content {
      .num {
        font-size: 1.3rem;
        font-weight: $font-bold;
        @media (max-width: 768px) {
          font-size: 1rem;
        }
      }
    }

    .btn-warning {
      color: $white !important;
      background-color: $green !important;
      border-radius: 8px;
      font-size: 1rem;
      font-weight: $font-semiBold;
      padding: 10px 16px;
      min-width: 150px;
      border: none;
      outline: none;

      &:focus {
        box-shadow: none !important;
        outline: none !important;
      }

      @media (max-width: 1366px) {
        min-width: 100px;
        padding: 10px 0px;
      }

      @media (max-width: 767px) {
        font-size: 0.8rem;
      }
    }

    &.make-payment-card {
      padding: 20px 15px;
      margin-bottom: 0px;
      position: unset;
      background-color: $concrete-gray;
      box-shadow: none !important;
      @media (max-width: 1200px) {
        margin-bottom: 20px;
      }
      @media (max-width: 992px) {
        box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px !important;
      }
      input[type="checkbox"] {
        zoom: 1.5;
      }
    }
  }

  .quick-payment-content {
    top: 60px;

    .css-1yvcp3e-MuiSelect-root-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 8.5px 14px;
    }
  }

  .accordion-section {
    .accordion-header {
      background-color: $concrete-gray;
      border-radius: 8px;
      overflow: auto;
      justify-content: end;
      width: 100%;
      white-space: break-spaces;
      padding: 0px 12px !important;
      &.Mui-expanded {
        width: 100%;
        overflow: auto;
        justify-content: flex-start;
        border-radius: 8px 8px 0px 0px;
        min-height: 50px;
      }
      .MuiAccordionSummary-content {
        .text-bold {
          font-weight: $font-bold;
        }
      }
    }
    .qp-accordion-details {
      padding: 10px 20px !important;
    }
  }

  .accordian-details {
    padding: 0px 20px 20px;
  }
}
.cvv-icon {
  margin-bottom: 8px;
}

.billdetails-content {
  padding: 20px 15px !important;
  background-color: $white !important;
  @include mainCard;
  margin-top: 0px;
  .listed-description {
    border: 1px solid #388bfd66;
    padding: 2px 5px;
    border-radius: 8px;
    background-color: aliceblue;
    h5 {
      text-align: left;
      font-size: 1rem;
      font-weight: $font-medium;
      color: $primary-color;
      border-bottom: none;
      margin-bottom: 10px;
      @media (max-width: 768px) {
        font-size: 0.875rem;
      }

      @media (max-width: 576px) {
        font-size: 0.813rem;
      }
    }
  }

  .billDetails-header {
    @include theme-aware("background", "lighten-1");
    display: table;
    width: 100%;
    padding: 10px 20px;
    margin: 15px 0 !important;
    @media (max-width: 1024px) {
      padding: 10px;
    }
    @media (max-width: 600px) {
      overflow: auto;
    }

    .heading-title {
      td {
        font-size: 0.9rem;
        font-weight: 500;
        text-align: left;
        padding: 5px 5px;
        @media (max-width: 600px) {
          min-width: 150px;
          text-align: left;
        }
        &:nth-child(n + 2) ~ td {
          text-align: right;
        }
      }
      &.makepayment-accordion-table {
        td {
          width: 20.5%;
          text-align: left;
          @media (max-width: 1024px) {
            width: auto;
          }
          &:nth-child(n + 3) ~ td {
            text-align: right;
            @media (max-width: 1024px) {
              text-align: center;
            }
            @media (max-width: 992px) {
              text-align: right;
            }
          }
        }
      }
    }

    .billing-table {
      td {
        font-size: 0.9rem;
        font-weight: $font-medium;
        text-align: left;
        padding: 15px;
        width: 20.5%;
        &:nth-child(n + 3) ~ td {
          text-align: right;
          @media (max-width: 1024px) {
            text-align: right;
          }
        }
      }
    }
  }
  .billing-data {
    td {
      text-align: left;
      padding: 5px 20px !important;
      font-size: 1rem;
      width: 20.5%;
      &:nth-child(2) {
        text-transform: capitalize;
        @media (max-width: 600px) {
          min-width: 150px;
        }
      }

      &:nth-child(n + 2) ~ td {
        text-align: right;
      }
    }
    .payment-input {
      display: flex;
      justify-content: flex-end;
      font-size: 1rem;
      align-items: center;
      div:nth-child(2) {
        width: 80%;
      }
      input {
        width: 100% !important;
      }
    }
  }
  .qp-billing-data {
    td {
      text-align: left;
      padding: 10px 15px !important;
      font-size: 1rem;
      width: 20.5%;
      @media (max-width: 576px) {
        text-align: left;
      }
      &:nth-child(2) {
        text-transform: capitalize;
        @media (max-width: 600px) {
          min-width: 150px;
        }
      }
      &:nth-child(n + 2) ~ td {
        text-align: right;
      }
    }
    .payment-input {
      display: flex;
      justify-content: flex-end;
      font-size: 1rem;
      align-items: center;
      input {
        width: 100px !important;
      }
    }
  }
  .account-details-header-info,
  .account-info {
    td {
      padding: 5px;
      text-align: left;
      width: 20.5%;
      @media (max-width: 600px) {
        min-width: 150px;
        text-align: left;
      }
      &:nth-child(2) {
        text-transform: capitalize;
        @media (max-width: 1024px) {
          text-align: left;
        }
      }
      &:nth-child(n + 2) ~ td {
        text-align: right;
      }
      .bill-payment-amt {
        display: flex;
        justify-content: end;
        align-items: center;
        input {
          width: 75%;
          text-align: right;
        }
        &.make-payment-input {
          input {
            width: 100px;
            text-align: right;
          }
        }
      }
    }
  }
  .payment-details-table {
    &.MuiAccordionDetails-root {
      padding: 10px 20px !important;
      border-radius: 0px 0px 8px 8px !important;
      width: 100%;
      overflow: auto;
      @media (max-width: 1024px) {
        padding: 10px !important;
      }
      .text-bold {
        font-weight: $font-bold;
      }
    }
  }
  .accordion-header {
    .MuiAccordionSummary-content {
      font-size: 1rem;
      font-weight: $font-semiBold;
      color: $oxfordblue;
      margin: 0px;
      justify-content: space-between;
      align-items: center;
      p {
        margin-bottom: 0;
        font-weight: 500;
        @media (max-width: 600px) {
          font-size: 0.8rem;
        }
      }
    }
  }
  .MuiCollapse-container {
    padding: 0 4px;
  }

  .accordion-table {
    .MuiTableCell-root {
      font-size: 1rem;
      border: none !important;
      padding: 6px 0;
      font-family: "poppins";
      @media (max-width: 600px) {
        min-width: 150px;
        text-align: left;
      }

      img {
        width: 160px;
        cursor: pointer;
      }

      svg {
        color: $primary-color;
        font-size: 2.375rem;
        margin-left: 34px;
      }

      .table-alert-icon {
        svg {
          color: $cornell-red;
          font-size: 1.125rem;
          margin-left: 0;
          margin-bottom: 5px;
          cursor: pointer;
        }
      }

      &.outstanding-amount {
        font-size: 1rem;
        font-weight: $font-semiBold;
      }

      &.payment-amount {
        font-size: 1rem;
        font-weight: $font-semiBold;

        span {
          font-size: 0.875rem;
          padding: 0px 5px;
          border: 1px solid $darkgrey;
          border-radius: 3px;
        }
      }
    }
  }

  .legal-actions {
    .MuiTableCell-root {
      color: $cornell-red !important;
      padding-bottom: 15px;
    }
  }

  .total-bill {
    border-top: 1px solid $grimmy-grey;

    .MuiTableCell-root {
      padding: 15px 0 10px !important;
    }
  }
}
.home-billing-navbar {
  display: flex;
  align-items: center;
  @media (max-width: 576px) {
    flex-wrap: wrap;
  }

  .billingdetails-warning-content {
    background-color: #fff4e5;
    padding: 2px 15px;
    font-size: 14px;
    color: black;
    width: 95%;
    margin: 0 auto;
    margin-top: 2px;
    border-radius: 2px;
    @media (max-width: 576px) {
      font-size: 0.813rem;
      margin: 10px 0px;
      width: 100%;
    }
    svg {
      color: orange;
    }
  }
  .warning-content-collapse {
    @media (max-width: 1024px) {
      font-size: 13px;
      width: 97%;
      margin-left: 0px;
    }
    @media (max-width: 992px) {
      margin-left: 0px;
      display: none;
    }
  }
}
.navbar-collapse {
  svg {
    @media (max-width: 768px) {
      margin-top: 15px;
    }
  }
}

.payplanoption-btn {
  color: $primary-color !important;
  border: 1px solid $green !important;
  padding: 0 5px 10px !important;
  box-shadow: none !important;
}

.download-button {
  margin-top: 15px;
  background-color: $light-yellow !important;
  padding: 5px 12px !important;
  color: $black !important;
  border-radius: 5px;
  border: 0 !important;
  outline: 0 !important;
  font-size: 0.8125rem;

  @media (max-width: 480px) {
    padding: 5px 0 !important;
    line-height: 1.3 !important;
  }
}

.MuiButton-label {
  font-size: 0.625rem !important;
  font-weight: $font-semiBold;
}

.custom-billing-radio {
  .MuiFormControlLabel-root {
    margin-left: 0px;
    margin-right: 0px;

    @media (max-width: 1200px) {
      white-space: pre-wrap;
    }
  }

  @media (max-width: 767px) {
    margin: 10px 0px;
  }

  fieldset {
    border: none !important;
  }
}

.quickpay-bill-details {
  padding: 20px;
  background-color: $flash-white;
  @media (max-width: 768px) {
    padding: 15px;
  }
}

.custom-loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.payAmountInput::-webkit-outer-spin-button,
.payAmountInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.fees-waived-input {
  .MuiInputBase-input,
  .MuiInputAdornment-positionStart p {
    font-size: 0.8125rem;
    font-weight: $font-bold;
    color: $persian-blue;
    padding: 0;
  }
}

.out-input {
  display: inline;
  width: 50%;
}

.payment-cards {
  border: 1px solid $primary-color;
  border-radius: 4px;
  margin: 0 10px 0 0;
}

.payment-cards.pay-btns {
  margin-right: 0;
  padding: 16px;
}

//table

.custom-table {
  width: 100%;
}

//dashboard-headers

.content-header {
  font-size: 1.5rem !important;
  text-transform: capitalize;
  color: #320469;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 1.2rem !important;
  }
  @media (max-width: 600px) {
    margin-bottom: 10px;
  }
}

.content-sub-header {
  font-size: 0.938rem !important;
}

//payment card
.authorize-checkbox {
  .css-pbhgrp-MuiButtonBase-root-MuiIconButton-root-MuiCheckbox-root {
    align-items: flex-start;
    padding-top: 0;
  }
}

//popup
.popupstyles {
  color: $white;
  padding: 10px !important;
  border-radius: 4px 4px 0px 0px;
  @include theme-aware("background", "color-background");
}
.hospital-dropdown {
  .MuiOutlinedInput-root {
    padding: 2px !important;
  }
}

.close-icon {
  color: $white;
}

.dialog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;

  .MuiTypography-root {
    font-family: "Poppins";
  }
  .MuiIconButton-root {
    padding: 10px;
    color: $white;
    &:hover {
      background-color: $light-purple;
    }
  }
  .MuiSvgIcon-root {
    font-size: 1.3rem;
  }
}
.modal-body {
  word-break: break-word;
}
.MuiDialogContent-root {
  padding: 0 !important;
}
.MuiDialogActions-root {
  padding: 0 !important;
  flex-wrap: wrap;
  .MuiButton-root {
    text-transform: none;
    min-width: 75px;
  }
  .MuiButton-label {
    font-size: 0.875rem !important;
    font-family: "Poppins";
    @media (min-width: 1920px) {
      font-size: 1rem !important;
    }
  }
}

.homebilling-accordiontable {
  padding: 10px 30px 10px 30px !important;
}

.billingamount-payamount {
  p {
    @media (max-width: 576px) {
      white-space: nowrap;
      font-size: 0.813rem;
    }
  }

  input {
    border: 1px solid $primary-color;
    border-radius: 4px;
    max-width: 100px;
  }
  .input-field-amount {
    display: flex;
    justify-content: center;
    @media (max-width: 576px) {
      justify-content: flex-end;
    }
    input {
      max-width: 150px;
      @media (max-width: 576px) {
        max-width: 90px;
      }
    }
  }
}

.amount-textfield {
  text-align: right;
  max-width: 115px !important;
  @media (max-width: 1024px) {
    max-width: 100px !important;
  }
  @media (max-width: 992px) {
    max-width: 145px !important;
  }
  @media (max-width: 767px) and (min-width: 600px) {
    max-width: 85px !important;
    vertical-align: text-bottom;
  }
  @media (max-width: 600px) and (min-width: 576px) {
    min-width: 215px !important;
    vertical-align: text-bottom;
  }
  @media (max-width: 576px) {
    max-width: 100px !important;
  }
}

.tooltip-billingamount {
  text-align: center;
  min-width: 20vw;

  @media (max-width: 1440px) {
    min-width: 17vw;
  }
}

.accountholder-name {
  font-size: 1.5rem !important;
  text-transform: capitalize;
  color: #320469;
  font-weight: 500;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 1.2rem !important;
  }
  @media (max-width: 576px) {
    font-size: 1rem;
  }
}

.qp-ssn {
  border-radius: 5px;
  background-color: $white;
  padding: 6px 10px;
  margin-bottom: 15px;
  @media (max-width: 600px) and (min-width: 576px) {
    padding: 8px 10px;
  }
  .security-number {
    font-size: 14px;
    @media (min-width: 1920px) {
      font-size: 18px;
    }
    @media (max-width: 767px) and (min-width: 576px) {
      font-size: 0.79rem;
    }
    @media (max-width: 576px) {
      margin-bottom: 3px;
      font-size: 0.875rem;
    }
  }
}

.pay-balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
  p {
    white-space: nowrap;
    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
  .bill-amount {
    font-size: 1.2rem;
    font-weight: $font-semiBold;

    @media (max-width: 1024px) {
      font-size: 1rem;
      margin-top: 5px;
    }
    @media (max-width: 600px) and (min-width: 576px) {
      margin-left: 22px;
    }
  }

  .MuiTypography-body1 {
    @media (max-width: 576px) {
      font-size: 0.875rem;
      font-family: "Poppins";
    }
  }
  .check-label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 576px) {
      justify-content: space-between;
    }
  }
  &.grand-total {
    margin: 10px 0px;
    border: 1px dashed darkgrey;
    border-radius: 5px;
    padding: 5px 10px;
    @media (max-width: 768px) {
      margin: 0px;
    }
  }
}

.pay-buttons {
  justify-content: center;
  align-items: end;
  position: absolute;
  bottom: 20px;
  width: 100%;

  @media (max-width: 1024px) {
    padding: 0px;
    justify-content: center;
  }
  @media (max-width: 992px) {
    position: unset;
    width: auto;
  }
  @media (max-width: 768px) {
    margin-top: 15px;
  }
}

//
.cardnumber-space {
  padding: 8.5px 10px !important;
}

.account-details {
  .content-sub-header {
    bottom: 8px !important;
  }
  .billing-ssn {
    border-radius: 5px;
    background-color: whitesmoke;
    padding: 6px 2px;
    text-align: center;
    @media (max-width: 600px) and (min-width: 576px) {
      margin: 0px;
      padding: 8px 10px;
      text-align: left;
    }
    @media (max-width: 576px) {
      margin: 20px 0px;
      padding: 8px 10px;
      text-align: left;
    }

    p {
      margin-bottom: 0px;
      @media (max-width: 1200px) {
        font-size: 0.95rem;
      }
      @media (max-width: 768px) {
        font-size: 0.9rem;
      }
      @media (max-width: 767px) and (min-width: 600px) {
        font-size: 0.86rem;
      }
      @media (max-width: 600px) {
        font-size: 0.77rem;
      }
    }
  }
  @-moz-document url-prefix() {
    option {
      font-weight: normal;
      display: block;
      white-space: nowrap;
      min-height: 1.2em;
      padding: 0px 2px 1px;
    }
  }
}

.pay-total-amount {
  .check-label {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 576px) {
      justify-content: flex-start;
      p {
        font-size: 0.813rem;
      }
    }
    input {
      zoom: 1.5;
      -moz-transform: scale(1.5, 1.5);
    }
    @-moz-document url-prefix() {
      input {
        margin-right: 15px !important;
      }
    }
  }
  .field-amount {
    margin-bottom: 6px;
    @media (max-width: 768px) {
      margin-bottom: 0px;
    }
  }
  .bill-amount {
    font-size: 1.2rem;
    @media (max-width: 992px) and (min-width: 768px) {
      margin-top: 6px;
    }
    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
  .doller-input {
    font-size: 1.2rem;
    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
}

.total-amount {
  @media (max-width: 576px) {
    padding: 0 !important;
  }
}
.payment-btn {
  @media (max-width: 992px) {
    margin-top: 8px;
  }

  @media (max-width: 600px) {
    justify-content: center !important;
  }
}

.outstanding-balance-info {
  .MuiAccordionSummary-content {
    p {
      margin: 0px 8px;
      flex: 1 1 0;
      &:nth-child(2) {
        flex: 1 1 275px;
        display: flex;
        flex-wrap: wrap;
      }
    }
    .payment-options-button {
      @media (max-width: 600px) {
        flex: 1 1 190px;
      }
    }
  }
}

.paymentamount-field {
  min-width: 6vw;

  @media (max-width: 576px) {
    min-width: 15vw;
  }
}

.closed-accounts-header {
  h5 {
    @media (max-width: 768px) {
      padding: 13px 5px 0 0;
    }

    @media (max-width: 576px) {
      margin-bottom: 8px;
    }
  }
}

.patient-account-title {
  @media (max-width: 768px) {
    td:first-child {
      width: 23vw !important;
    }

    td:last-child {
      text-align: left !important;
      padding: 0 15px !important;
    }
  }
}

.patient-account-details {
  @media (max-width: 768px) {
    td:first-child {
      width: 17% !important;
    }

    .payAmountInput {
      width: 75px !important;
    }
  }

  @media (max-width: 576px) {
    .payAmountInput {
      width: 65px !important;
    }
  }
}
.make-payment-section {
  background-color: $concrete-gray;
  box-shadow: rgb(99 99 99 / 20%) -5px 2px 8px 0px;
  margin: 0 auto;
  width: 86%;
  border-radius: 8px;
  max-height: calc(100vh - 250px);

  @media (max-width: 1366px) {
    min-height: calc(100vh - 145px);
    width: 90%;
  }
  @media (max-width: 1024px) {
    width: 95%;
  }
  @media (max-width: 992px) {
    min-height: 100vh;
    max-height: 100%;
    box-shadow: none;
  }
}
.pay-balance-card {
  .pay-balance-card-one {
    .payment-method {
      font-size: 0.968rem;

      @media (max-width: 576px) {
        font-size: 0.875rem;
      }
    }
  }

  .pay-balance-card-two {
    overflow-y: auto;
    min-height: calc(100vh - 250px);
    max-height: calc(100vh - 250px);
    border-radius: 0px 8px 8px 0px;
    @media (max-width: 1366px) {
      min-height: calc(100vh - 145px);
    }
    @media (max-width: 768px) {
      border-radius: 8px;
      min-height: 100vh;
    }
    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $warm-white;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $gray-silver-shade;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $gray-silver;
    }
    .listed-description {
      border: 1px solid #388bfd66;
      padding: 2px 5px;
      border-radius: 8px;
      background-color: aliceblue;

      h5 {
        margin-bottom: 0px;
        font-size: 15px;
      }
    }
    .warning-message-card {
      background-color: rgb(255, 244, 229);
      padding: 15px;
      border-radius: 8px;
    }

    .warning-message {
      color: $dark-orange;
      padding: 8px 0px;

      @media (max-width: 576px) {
        font-size: 0.82rem;
      }
    }
  }
}
.warning-message-card {
  background-color: rgb(255, 244, 229);
  padding: 10px 15px;
  font-size: 14px;
  color: black;
  width: 66%;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 90%;
    padding: 5px 10px;
  }
  svg {
    color: orange;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
}
.open-accounts-header {
  display: revert !important;
  padding: 10px !important;
}

.disable-viewplan-details {
  opacity: 0.55 !important;
}

.amt-txtRight {
  text-align: right;
}
.placeholderfontsize::placeholder {
  font-size: small !important ;
}
.paddingRight {
  padding-right: 50px;
}
.popup-heder {
  padding: 8px 16px !important;
  .MuiTypography-root {
    font-size: 1rem !important;
  }
}
.authentication-content {
  max-width: 100%;
  p {
    font-family: "poppins" !important;
  }
  .authentication-heading {
    color: $black;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .authentication-description {
    font-size: 14px;
    color: $black;
    margin-bottom: 10px;

    @media (min-width: 1920px) {
      font-size: 1rem;
    }
  }
  .scan-QR-code {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      min-width: 200px;
      max-height: 200px;
    }
    .enter-key {
      font-weight: 500;
      cursor: pointer;
    }
  }
  .MuiDialogActions-root {
    @media (max-width: 576px) {
      flex-wrap: wrap !important;
    }
  }
}
.pay-amount {
  font-weight: 600;
}
.makepayment-table {
  overflow-y: auto !important;
  min-height: 100vh;
}

.amount-field-box {
  border-radius: 5px;
  background-color: whitesmoke;
  text-align: center;
  font-size: 16px;
  padding: 5px;
  box-shadow: rgb(0 0 0 / 2%) 0px 1px 3px 0px, rgb(27 31 35 / 15%) 0px 0px 0px 1px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 768px) and (min-width: 576px) {
    margin-bottom: 15px;
  }
  @media (max-width: 576px) {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    margin-bottom: 15px;
  }
  .para {
    font-size: 1.1rem !important;
    @media (max-width: 768px) {
      font-size: 1rem !important;
    }
  }
}
.loader {
  position: absolute;
  top: 0px;
  left: 50%;
}
.homebilling-accordion-section {
  margin-top: 10px;
  .accordion-heading-style {
    font-size: 1.25rem;
    color: #320469 !important;
    font-weight: $font-medium;
    word-break: break-all;
    text-transform: capitalize;
  }
  .homebilling-accordion-details {
    &.MuiAccordionDetails-root {
      width: 100%;
      overflow: auto;
      padding: 0px 10px 10px !important;
    }
  }
  .qp-accordion-details {
    &.MuiAccordionDetails-root {
      width: 100%;
      overflow: auto;
      padding: 0px 10px 10px !important;
    }
  }
  .qp-closed-accounts {
    display: table;
    width: 100%;
    padding: 0px 20px;
    @media (max-width: 1024px) {
      padding: 0px 10px;
    }
    @media (max-width: 600px) {
      overflow: auto;
      width: 100%;
    }
  }
}
.homebilling-table-container {
  overflow: auto;
  width: 100%;
  white-space: nowrap;
  display: table;
}
.main-container-sm {
  @media (max-width: 992px) {
    margin-top: 20px;
  }
  @media (max-width: 576px) {
    margin-top: 80px;
  }
}

//void-refund-popup//
.void-refund-container {
  .void-refund-details {
    display: flex;
    @media (max-width: 576px) {
      justify-content: space-between;
    }
  }
  .refund-check {
    zoom: 1.3;
    margin-right: 5px;
    vertical-align: middle;
  }
  .void-refund-table {
    .MuiTableCell-head {
      background: $metallic-silver !important;
    }
    tbody {
      tr {
        &.MuiTableRow-root {
          vertical-align: baseline;
        }
        td {
          &.MuiTableCell-root {
            padding-bottom: 0px !important;
          }
        }
      }
    }
    .error-text {
      right: 0px;
    }
  }
  .void-table {
    .MuiTableCell-head {
      background: $metallic-silver !important;
    }
    thead {
      tr {
        th {
          &:nth-child(3) {
            text-align: right;
            white-space: nowrap;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:nth-child(3) {
            text-align: right;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.void-input {
  display: flex;
  justify-content: end;
  align-items: end;
  flex-direction: column;
  .MuiTextField-root {
    width: 70%;
    @media (max-width: 768px) {
      width: 100%;
    }
    .MuiOutlinedInput-input {
      padding-left: 0px !important;
    }
  }
}

//void-refund-popup//

// RP settlement popups start

.settlement-amount {
  border: 1px solid gray;
  border-radius: 4px;
  text-align: center;
  padding: 5px;
  min-height: 110px;
  .settlement-amount-title {
    @media (max-width: 576px) {
      margin-bottom: 0px !important;
    }
  }
}
.settlement-popup {
  input[type="checkbox"] {
    zoom: 1.3;
    vertical-align: middle;
  }
  textarea,
  input[type="number"] {
    border-radius: 8px;
    padding: 8.5px 14px !important;
    font-size: 0.875rem !important;
  }
  input[type="number"] {
    padding: 2px 5px !important;
    border-radius: 4px;
    border: 1px solid black;
  }
}
.revised-amount {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.settlement-addnotes {
  @media (max-width: 425px) {
    margin-top: 10px;
  }
}
// RP settlement popups end

// cross browser filter remove code start
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
// cross browser filter remove code end
