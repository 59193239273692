.progresscontainer {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 15px;
  display: block;
  /* height: 30px; */
  float: left;
}
.progressbar {
  counter-reset: step;
  padding: 0;
}
.progressbar li {
  list-style-type: none;
  float: left;
  width: 207px;
  position: relative;
  text-align: left;
  font-weight: 600;
}
.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  height: 30px;
  width: 30px;
  line-height: 30px;
  border: 2px solid #ddd;
  display: inline-block;
  text-align: center;
  margin: 0px 10px 5px 10px;
  border-radius: 50%;
  background-color: white;
}
.progressbar li:after {
  content: "";
  position: absolute;
  width: 25%;
  height: 2px;
  background-color: #ddd;
  top: 15px;
  left: -0%;
  z-index: -1;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  color: #0f75bc;
}
.progressbar li.active:before {
  border-color: #0f75bc;
}
.progressbar li.active + li:after {
  background-color: #0f75bc;
}

@media screen and (max-width: 1024px) {
  .progresscontainer {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 40px;
    display: block !important;
  }
  .progressbar {
    display: flex;
  }
  .progressbar li {
    width: 100%;
  }
  .progresscontainer {
    height: 30px;
    float: left;
  }
}

@media screen and (max-width: 768px) {
  .progresscontainer {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 40px;
    display: block;
  }
  .progressbar {
    display: flex;
  }
  .progressbar li {
    width: 40%;
  }
  .progresscontainer {
    max-height: 30px;
    float: left;
  }
}

@media screen and (max-width: 480px) {
  .progresscontainer {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 30px;
    display: block;
  }
  .progressbar {
    display: flex;
    justify-content: space-around;
  }
  .progressbar li {
    width: auto !important;
    text-align: center;
  }
  .progresscontainer label {
    font-size: 12px;
  }
  .progresscontainer {
    height: 40px;
    float: left;
  }
}

@media screen and (max-width: 320px) {
  .progresscontainer {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 30px;
    display: block;
  }
  .progressbar {
    display: flex;
  }
  .progressbar li {
    width: auto !important;
  }
  .progresscontainer {
    height: 70px;
    float: left;
  }
}

@-moz-document url-prefix() {
  .progresscontainer {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 15px;
    display: block ruby;
    float: initial;
  }
  .progressbar {
    counter-reset: step;
    padding: 0;
    margin-bottom: 0;
  }
}
