.reportContainer {
  display: flex;
  flex-direction: column;
  margin: 0;
  a {
    &:last-child {
      color: $red;
      margin-top: 0 !important;
    }
  }
}
.unlock-user {
  margin-left: -4px;
}
