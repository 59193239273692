@import "../mixins/mixins";

.maskfieldcc {
  padding: 0px !important;
}
.input-field {
  font-family: "poppins";
  .form-group {
    text-align: left;
    position: relative;
    margin-bottom: 24px;
    &.account-number {
      padding: 0px 42px 4px;
      @media (max-width: 576px) {
        padding: 0px;
      }
    }
    fieldset {
      margin: 0 !important;
    }
    &.captcha {
      margin: 16px 25px 0px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 425px) {
        margin: 45px 25px 0px !important;
      }
      .quickpay-captcha {
        color: $red;
        font-size: 0.8rem;
      }
    }
    label {
      font-size: 0.875rem;
      color: $lightblue;
      font-weight: $font-semiBold;
      text-align: left;
      span {
        font-size: 0.625rem;
      }
      @media (min-width: 1920px) {
        font-size: 1rem;
      }
    }
    .label-spacing {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .user-verify {
      svg {
        color: green;
        font-size: 1.2rem;
        cursor: pointer;
      }
    }
    .user-not-verify {
      svg {
        color: red;
        font-size: 1.2rem;
        cursor: pointer;
      }
    }
    .MuiFormHelperText-root {
      position: absolute;
      padding: 0px 2px;
      line-height: 1.1;
      font-size: 0.8rem;
      color: #ff0000;
      font-family: "poppins" !important;
      margin: 2px 0px 0px 0px !important;
    }
    .form-control {
      @include formcontrol;
      text-transform: capitalize;
      background-color: $white;
      .MuiOutlinedInput-input {
        background-color: $white;
        font-family: poppins;
        border-radius: 6px;
        padding: 8.5px 14px;
      }
      .Mui-disabled {
        color: $black !important;
        opacity: 1 !important;
        -webkit-text-fill-color: $black;
      }
      ::-webkit-input-placeholder {
        color: $black;
      }
      :-moz-placeholder {
        /* Firefox 18- */
        color: $black;
        opacity: 1;
      }

      :-ms-input-placeholder {
        color: $black;
      }

      .MuiSelect-select:focus {
        background-color: transparent !important;
      }
      .MuiSvgIcon-root {
        color: $grey-web;
      }
    }
    .MuiOutlinedInput-input {
      border-radius: 8px;
      padding: 8.5px 14px !important;
      font-size: 0.875rem !important;
      @media (min-width: 1920px) {
        font-size: 1rem !important;
      }
    }
    .MuiOutlinedInput-root {
      padding-right: 0px !important;
    }

    .date-picker {
      &.css-15fev7r-MuiGrid-root {
        .MuiOutlinedInput-root {
          border-radius: 6px !important;
          font-size: 0.875rem;
          background-color: $white;
          font-family: "poppins";
          padding-right: 12px !important;
          padding: 2px 0px;

          .MuiOutlinedInput-inputMarginDense {
            padding: 0 !important;
          }
          .MuiIconButton-root {
            color: $grey-web;
          }
        }
        .css-4mzek5-MuiFormControl-root-MuiTextField-root {
          margin: 0 !important;
          width: 100%;
          .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
            padding: 8.5px 14px !important;
            box-shadow: none !important;
          }
        }
      }
    }
    .custom-date-picker {
      .MuiOutlinedInput-root {
        padding: 0px !important;
      }
      .MuiButtonBase-root {
        padding: 10px 6px !important;
      }
      .MuiOutlinedInput-input {
        padding-right: 5px !important;
      }
    }

    .date-picker-disable {
      &.css-15fev7r-MuiGrid-root {
        .MuiOutlinedInput-root {
          background-color: rgba(143, 143, 143, 0.12) !important;
        }
      }
    }

    .date-picker-errorBorder {
      &.css-15fev7r-MuiGrid-root {
        .MuiOutlinedInput-root {
          border: 1px solid red;
          &.Mui-focused fieldset {
            border: 1px solid red;
          }
        }
      }
    }

    .circle-icon {
      padding-right: 18.8px;
      color: $grey-web;
      margin-bottom: 8px;
      cursor: pointer;
      @media (max-width: 576px) {
        padding-right: 2.8px;
      }
      em {
        font-size: 0.875rem;
        padding-left: 4.8px;
        color: $darkensilver;
      }
    }
    .need-help em {
      @media (max-width: 576px) {
        font-size: 0.75rem;
      }
    }
    .error-text {
      font-size: 0.8rem;
      color: $red;
      position: absolute;
      padding-top: 5px;
      font-family: "poppins" !important;
    }
  }
  .cardnumber-input {
    &.MuiInputAdornment-root {
      padding: 0px 8px;
    }
  }

  .component-error-text {
    color: $red;
    font-size: 0.75rem;
    padding: 6px 0px 0px;
  }
  i {
    cursor: pointer;
  }
}
//check-box-color-when-checked//
input[type="checkbox"] {
  accent-color: #005cc8;
}
//check-box-color-when-checked//

//edge-password-reveal//
input[type="password"] {
  &::-ms-reveal {
    display: none;
  }
}
//edge-password-reveal//

// DatePicker//
.MuiPickerView-root {
  .MuiPickersCalendarHeader-root {
    background-color: $milky-gray;
    margin: 0px 0px 8px 0px;
    min-height: 50px;
  }
  .MuiPickersCalendar-daysHeader {
    background-color: rgb(63 81 181);
    margin-bottom: 10px;
    .MuiTypography-root {
      font-weight: $font-bold;
      margin: 0px 2px;
      color: $white;
    }
  }
  .MuiPickersCalendar-root {
    min-height: 220px;
    overflow-y: auto;
    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $warm-white;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $gray-silver-shade;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $gray-silver;
    }
    .MuiPickersDay-root {
      border-radius: 25px;
      margin: 2px;
      color: $black;
      &.MuiPickersDay-root.Mui-selected {
        background-color: $moderate-blue;
        color: $white;
        font-weight: $font-bold;
      }
      &.MuiPickersDay-today:not(.Mui-selected) {
        background-color: rgb(248 249 250) !important;
        font-weight: $font-medium;
        border: 1px solid $moderate-blue;
        color: $moderate-blue;
      }
      &:hover {
        background-color: rgb(99 99 99 / 20%);
      }
      &.Mui-disabled {
        background-color: rgb(105 105 105 / 13%);
      }
    }
  }
}
// DatePicker//

//Fieldset Design used login screens//
fieldset {
  border: 2px solid #bec0d3;
  padding: 0px 30px 20px;
  margin: 0px 50px;
  border-radius: 4px;

  @media (max-width: 1440px) {
    margin: 0 1.25rem;
  }
  @media (max-width: 767px) {
    margin: 0;
    padding: 0.3125rem 0.625rem;
  }
  &.registration-fieldset {
    margin: 0px 20px;
    padding: 0px 20px;
  }
  &.secure-login-fieldset {
    margin: 0px 16px;
    @media (max-width: 767px) {
      margin: 0;
      padding: 5px 14.4px;
    }
  }
}
//Fieldset Design used login screens//

//legend-css//
legend {
  font-size: 1rem;
  width: 69%;
  color: $lightblue;
  text-align: left;
  width: auto;
  padding: 0px 10px;
  margin-bottom: 16px;
  @media (max-width: 768px) {
    font-size: 0.875rem;
    margin: 0;
    padding: 5px 10px;
  }
  @media (max-width: 576px) {
    font-size: 0.8rem;
  }
}
//legend-css//

.med1-input {
  position: relative;

  .fas {
    position: absolute;
    top: 18px;
    color: $blue-magneta;

    &.lock-icon {
      left: 15px;
    }

    &.eye-icon {
      right: 15px;
    }
  }
}

.signup-fields {
  input {
    padding-left: 0.625rem;
  }
}
.security-input {
  position: relative;
  border: none;
  .eye-icon {
    right: 0.9375rem;
    position: absolute;
    top: 18px;
    color: $blue-magneta;
  }
}
.user-security-content {
  padding: 0;
  margin-top: 15px;
}
.agree-terms {
  .MuiCheckbox-root {
    @include theme-aware("color", "linear-2");
  }
  font-size: 0.875rem !important ;
  @media (min-width: 1920px) {
    font-size: 1rem !important;
  }
  @media (max-width: 1024px) {
    font-size: 0.85rem !important;
  }
  .MuiFormControlLabel-label {
    font-family: "Poppins" !important;
    color: $dark-charcoal !important;
    text-align: justify;
    font-size: 0.875rem !important ;

    @media (min-width: 1920px) {
      font-size: 1rem !important;
    }

    @media (max-width: 1024px) {
      font-size: 0.85rem !important;
    }
    @media (max-width: 576px) {
      word-break: break-all;
    }
  }
}
//bill-details popover

.legal-popover {
  font-family: "poppins";
  h6 {
    font-size: 0.875rem;
    color: $dark-charcoal;
    font-weight: $font-semiBold;
    padding-bottom: 10px;
  }
  .legalTooltip-row {
    display: flex;
    justify-content: space-between;
    margin: 5px 0px;
    p {
      padding: 5px 0px 0px;
      margin-bottom: 0px;
      font-size: 0.875rem;
      color: $platinum-gray;
      font-weight: $font-medium;
    }
    h6 {
      padding-bottom: 0;
    }
    &:nth-last-child(1) {
      p,
      h6 {
        color: $dark-shade-red;
      }
    }
  }
}
//password input-field popover css
.password-info {
  color: $black;
  padding: 10px;
  font-family: "poppins";
  h6 {
    font-size: 1rem;
    font-weight: $font-medium;
    color: $primary-color;
    padding-bottom: 3px;
  }
  ul {
    padding: 0 0;
    margin: 0;
    li {
      list-style: none;
      font-size: 0.75rem;
      color: $platinum-gray;
      padding: 0 0 4px 0;
    }
  }
}
.radio-btns {
  display: flex;
  @media (max-width: 576px) {
    padding: 0 20px;
    flex-wrap: wrap;
  }
  .MuiFormControlLabel-label {
    color: $gray !important;
    font-family: "poppins" !important;
    font-size: 0.875rem !important;
    font-weight: $font-medium !important;
    margin-right: 30px;
    @media (min-width: 376px) and (max-width: 480px) {
      margin-right: 23px;
    }
    @media (min-width: 1920px) {
      font-size: 1.25rem !important;
    }
  }
  .payment-radio-btns {
    div {
      @media (max-width: 767px) {
        width: 50%;
      }
      @media (max-width: 425px) {
        width: 100%;
      }
    }
  }
}

//accordion
.accordion-section {
  margin-bottom: 10px !important;
  border-radius: 8px !important;
  @media (max-width: 767px) {
    overflow: scroll;
    white-space: nowrap;
  }

  &:before {
    display: none;
  }
  .accordion-header {
    padding: 0 20px !important;
  }

  .MuiAccordionSummary-content {
    h1 {
      color: $primary-color;
      font-size: 1.1rem;
      font-family: "poppins";
      font-weight: $font-medium;
    }
  }
  .accordion-details {
    padding: 20px !important;
    .d-flex {
      span {
        @media (max-width: 576px) {
          margin-bottom: 25px;
          min-height: 50px;
        }
      }
    }
    .MuiAccordionSummary-content {
      @media (max-width: 576px) {
        flex-direction: column;
      }
      .edit-icon {
        margin-left: 96px;
        @media (max-width: 576px) {
          margin-left: 128px;
          margin-top: -40px;
        }
      }
    }
    .MuiAccordionSummary-root {
      .MuiAccordionSummary-expandIcon {
        @media (max-width: 576px) {
          margin-right: -12px;
          margin-bottom: -57px;
        }
      }
    }

    .MuiAccordion-root {
      .MuiButtonBase-root {
        background-color: rgba(0, 157, 189, 0.1) !important;
      }
    }
    .MuiAccordionSummary-content {
      align-items: center;
      margin: 5px 0;
      @media (max-width: 576px) {
        align-items: start;
      }
      &.Mui-expanded {
        margin: 5px 0;
      }
    }
    p {
      font-family: "poppins" !important;
      @media (max-width: 767px) {
        margin-left: 35px;
      }
    }
    .MuiCollapse-wrapperInner {
      background-color: $white;
      padding: 16px 14px;
    }
    img {
      margin-right: 8px;
      width: 40px;
      border-radius: 4px;
    }
  }
}

//
.account-balance {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px 15px 0px;
  p {
    margin-bottom: 0;
    font-weight: $font-medium;
    font-size: 0.938rem;
    @media (min-width: 1536px) {
      font-size: 1.5rem;
    }
    @media (max-width: 576px) {
      font-size: 0.738rem;
    }
  }

  h3 {
    color: $primary-color;
    font-weight: $font-semiBold;
    font-size: 1.5rem;
    @media (min-width: 1536px) {
      font-size: 1.6rem;
    }
    @media (max-width: 1024px) {
      font-size: 1rem;
    }
    @media (max-width: 576px) {
      font-size: 1rem;
    }
  }
}
//dropdown options

.MuiMenu-list {
  .MuiMenuItem-root {
    font-family: "poppins" !important;
    text-transform: capitalize;
    .MuiSvgIcon-root {
      color: $primary-color;
    }
  }
}
//popover
.hospital-name {
  font-family: "poppins" !important;
}
.fieldstyles {
  .MuiOutlinedInput-input {
    padding: 10.5px 10px !important;
  }
}
.quickpayErr {
  color: $red;
}
.heading {
  h2 {
    font-size: 1.375rem !important;
    @media screen and (max-width: 1024px) {
      font-size: 1.063rem !important;
    }
  }
}
//Debit/Credit card
.debitcard {
  background-color: $milky-gray !important;
  margin: 15px 0px !important;
  @include mainCard;
  box-shadow: none !important;
  padding: 20px 10px 0px 10px;
  .debitcard-details {
    display: flex;
    @media (max-width: 768px) {
      font-size: 0.875rem;
    }
    @media (max-width: 576px) {
      justify-content: space-between;
    }
    .label {
      font-weight: $font-medium;
      white-space: nowrap;
      margin-right: 8px;
    }
    .value {
      word-break: break-all;
    }
  }
}

.inputbg {
  .MuiSelect-root {
    padding-right: 32px !important;
  }
}
.echeck-stepper {
  .progresscontainer {
    margin: 30px 0px 0px 0px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 1024px) {
      margin: 30px 0px 15px 0px;
      display: flex !important;
      justify-content: center;
    }
  }
  .progressbar li {
    @media screen and (max-width: 1024px) {
      width: fit-content;
    }
  }
}
.change-password-container,
.reset-password-container {
  @include mainCard;
}
.reset-password-container {
  max-width: 80vw;
  margin: 60px auto 0;
  text-align: center;
  .admin-logo {
    margin: 0 0 20px 0;
  }
}
.creditcard-content {
  display: flex;
  justify-content: center;
  padding: 20px;
  .dashboard-content-card {
    .e-check-bg {
      border-radius: 8px;
      margin: 0px;
      .echeck-card {
        @include mainCard;
        margin-top: 0px;
        @media (max-width: 768px) {
          padding: 20px 10px;
        }
        .content-title {
          font-size: 1.25rem;
          color: $primary-color;
          font-weight: $font-medium;
          @media (max-width: 576px) {
            font-size: 1rem;
          }
        }
        .card-title-header {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          flex-wrap: wrap;
        }
        .label-spacing {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .MuiFormHelperText-root {
          position: absolute;
          font-size: 0.8rem;
          padding: 0px 2px;
          line-height: 1.1;
          font-family: "poppins" !important;
          margin: 2px 0px 0px 0px !important;
          color: #ff0000 !important;
        }
      }
    }
  }
}
.echeck-credit-container {
  @media (max-width: 1170px) {
    margin-left: 0px !important;
  }
  .dashboard-content {
    padding: 0 !important;
  }
  .dashboard-content-card {
    @include mainCard;
    @media (max-width: 768px) {
      padding: 20px 10px;
    }
    .e-check-bg {
      .cardpersonal-details {
        background-color: $milky-gray !important;
        margin-top: 0px;
        box-shadow: none !important;
        @include mainCard;
        .edit-card-details {
          display: flex;
          @media (max-width: 768px) {
            font-size: 0.875rem;
          }
          @media (max-width: 576px) {
            justify-content: space-between;
          }
          .label {
            font-weight: $font-medium;
            white-space: nowrap;
            margin-right: 8px;
          }
          .value {
            word-break: break-all;
          }
        }
      }
    }
  }
}

//payment-successful-container//

.payment-successful-container {
  .payment-content-cp {
    background-color: $light-orchid;
    padding: 20px;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    border-radius: 8px;
    @media (max-width: 768px) {
      padding: 20px 10px;
    }
    .main-container {
      .payment-success-container {
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          color: $tahiti-gold;
          margin-right: 25px;
          font-size: 2.188rem;
          @media (max-width: 768px) {
            font-size: 1.875rem;
          }
          @media (max-width: 600px) {
            font-size: 1.563rem;
            margin-right: 16px;
          }
        }
        .successfull-text {
          color: $tahiti-gold;
          font-weight: $font-light;
          font-size: 2.813rem;
          @media (max-width: 600px) {
            font-size: 1.75rem;
          }
        }
      }
      .success-transaction {
        text-align: center;
        .thankyou-text {
          font-size: 1.625rem;
          font-weight: $font-regular;
          @media (max-width: 768px) {
            font-size: 1.5rem;
          }
          @media (max-width: 600px) {
            font-size: 1.25rem;
          }
        }
      }
      .confirm-transaction-ids {
        font-weight: $font-medium;
        justify-content: space-between;
        @media (max-width: 768px) {
          font-size: 0.875rem;
        }
        .confirmation-number {
          display: flex;
          @media (max-width: 600px) {
            margin-bottom: 10px;
          }
        }
        .transaction-details {
          display: flex;
          justify-content: flex-end;
          @media (max-width: 600px) {
            margin-bottom: 10px;
            justify-content: flex-start;
          }
        }
        p {
          white-space: nowrap;
          margin: 0px 5px 0px 0px;
          @media (min-width: 1920px) {
            font-size: 1.2rem;
          }
        }
        .value {
          word-break: break-all;
          padding: 0px 5px;
          @media (min-width: 1920px) {
            font-size: 1.2rem;
          }
        }
      }
      .transaction-details-container {
        padding: 20px;
        box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
        border-radius: 8px;
        background-color: $white;
        @media (max-width: 600px) {
          padding: 10px;
        }
        .details {
          display: flex;
          font-size: 1rem;
          margin-bottom: 20px;
          @media (min-width: 1920px) {
            font-size: 1.2rem;
          }
          @media (max-width: 600px) {
            font-size: 0.688rem;
            justify-content: space-between;
          }
          .label {
            font-weight: $font-medium;
            white-space: nowrap;
            margin-right: 5px;
          }
          .value {
            word-break: break-all;
          }
          .outstanding-amount {
            font-size: 1.25rem;
          }
        }
        .outstanding-amount {
          font-weight: $font-medium;
          border-top: 1px dashed $black;
          justify-content: center;
          @media (max-width: 600px) {
            font-size: 0.688rem;
            justify-content: flex-start;
            padding: 0px 5px;
          }
          .outstanding-text {
            font-weight: $font-semiBold;
          }
        }
      }
    }
  }
}
.form-autocomplete {
  .MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding: 2px !important;
  }
  .MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
    font-family: "Poppins";
    padding-right: 50px !important;
  }
}

.MuiAutocomplete-option {
  font-family: "poppins";
  padding: 6px 16px;
  font-size: 1rem;
  @media (max-width: 767px) {
    padding: 6px 10px;
    font-size: 0.75rem;
  }
}

//payment-successful-container//

//view-receipt-dialog-box//

.receipt-dialog-container {
  .MuiPaper-root {
    max-width: calc(100% - 650px) !important;
    @media (min-width: 1400px) {
      max-width: calc(100% - 850px) !important;
    }
    @media (max-width: 1200px) {
      max-width: calc(100% - 550px) !important;
    }
    @media (max-width: 768px) {
      max-width: calc(100% - 250px) !important;
    }
    @media (max-width: 576px) {
      max-width: calc(100% - 50px) !important;
    }
  }
  .dialog-title {
    @include theme-aware("background", "color-background");
    display: flex;
    justify-content: center;
    padding: 15px 10px;
    @media (max-width: 576px) {
      padding: 10px;
    }
    .payment-receipt {
      color: $white;
      font-size: 1.625rem;
      font-weight: $font-semiBold;
      @media (max-width: 1024px) {
        font-size: 1.375rem;
      }
      @media (max-width: 576px) {
        font-size: 1rem;
      }
    }
  }
  .dialog-body {
    padding: 20px 10px;
    @media (max-width: 576px) {
      font-size: 0.625rem;
    }
  }
  .receipt-box {
    padding: 15px;
    @media (max-width: 576px) {
      font-size: 0.75rem;
    }
    .receipt-value {
      white-space: pre-wrap;
      word-break: break-all;
    }
  }
}

//view-receipt-dialog-box//

.billsnotes {
  margin-top: 20px;
}
.pagination {
  display: flex;
  margin: 15px 0;
  .currentpage {
    margin: 0 15px;
  }
  .nextprevious {
    margin-right: 2%;
    position: absolute;
    right: 0;
    font-family: "poppins" !important;
    button {
      margin-right: 10px;
      border: 1px solid #d7e0e8;
      padding: 5px 10px;
      border-radius: 4px;
    }
  }
}
.billsnotes {
  margin-top: 20px;
  a {
    color: $azure;
    text-decoration: none !important;
    &.hover {
      color: #0056b3;
    }
  }
}
.main-container-card {
  @include mainCard;
  .payments-buttons {
    position: sticky;
    top: 57px;
    z-index: 11;
    padding: 10px;
    background-color: $metallic-silver;
    border-radius: 8px;
    margin-bottom: 10px;
    display: flex;
    @media (max-width: 576px) {
      padding: 0px 5px;
      justify-content: center;
    }
  }
  .scheduled-amount {
    display: flex;
    justify-content: end;
    margin-top: 20px;
    .amount-text {
      color: rgb(109, 109, 109);
      font-weight: $font-medium;
      font-size: 0.875rem;
      @include theme-aware("background", "lighten-1");
      border-radius: 8px;
      padding: 2px 8px;
      margin-bottom: 4px;
      .amount-scheduled {
        color: rgb(70, 70, 70);
        padding-left: 5px;
        font-weight: $font-semiBold;
      }
    }
  }
}
.payment-method-container {
  @include mainCard;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  position: relative;
  background-color: $white;
  @media (max-width: 768px) {
    padding: 20px 10px;
  }
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $warm-white;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $gray-silver-shade;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $gray-silver;
  }

  .payment-header {
    position: sticky;
    top: -20px;
    background-color: $white;
    z-index: 1;
    width: 100%;
    padding: 0px 10px;

    .payment-header-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 768px) {
        flex-wrap: wrap;
      }
    }
  }
  .payment-method-heading {
    text-align: left;
    padding: 0px 5px 0px 0px;
    font-size: 1.25rem;
    font-weight: $font-medium;
    color: $primary-color;
    border-bottom: none;
    @media (min-width: 1920px) {
      font-size: 1.5rem;
    }
    @media (max-width: 768px) {
      font-size: 1.1rem;
    }
  }
}
.paymentmethods-cp .MuiAccordionSummary-content {
  margin: 6px 0px !important;
  align-items: center;
}
.paymentmethods-cp .e-check-heading {
  @media (min-width: 1536px) {
    flex-basis: 49%;
  }
  @media (max-width: 768px) {
    flex-basis: 65%;
  }
  @media (max-width: 576px) {
    flex-basis: 64%;
  }
}
.paymentmethods-cp .card-heading {
  @media (min-width: 1536px) {
    flex-basis: 49%;
  }
  @media (max-width: 768px) {
    flex-basis: 62%;
  }
  @media (max-width: 576px) {
    flex-basis: 65%;
  }
}
.paymentmethods-cp .card-name {
  display: flex;
  align-items: center;
}
.paymentmethods-cp .card-name span {
  @media (min-width: 1920px) {
    font-size: 1.125rem;
  }
  @media (max-width: 768px) {
    white-space: nowrap;
  }
  @media (max-width: 576px) {
    white-space: pre-wrap;
    font-size: 0.8rem;
  }
  @media (max-width: 425px) {
    white-space: break-spaces;
    font-size: 0.7rem;
  }
}
.paymentmethods-cp .card-name img {
  width: 40px;
  height: 30px;
  margin: 10px 5px;
  @media (max-width: 576px) {
    width: 25px;
    height: 20px;
  }
  @media (max-width: 425px) {
    width: 20px;
    height: 15px;
  }
}

.paymentmethods-cp .edit-icon {
  outline: none;
}

.paymentmethods-cp {
  p {
    padding-top: 15px;
    @media (max-width: 768px) {
      padding-top: 5px;
    }
  }
  .edit-delete-icons {
    display: flex;
    .edit-icon-echeck {
      outline: none;
    }
    .MuiButtonBase-root {
      @media (max-width: 768px) {
        padding: 6px 12px;
      }
      @media (max-width: 576px) {
        padding: 6px 6px;
      }
    }
    svg {
      @media (max-width: 768px) {
        font-size: 1.1rem;
      }
    }
  }
}

.customer-details p {
  font-family: "poppins" !important;
}

.date-edit-delete-accordion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 30%;
  @media (min-width: 1536px) {
    flex-basis: 26%;
  }
  @media (max-width: 768px) {
    flex-basis: 35%;
  }
  @media (max-width: 576px) {
    flex-basis: 40%;
  }
}
.e-check-icon {
  @media (max-width: 768px) {
    flex-basis: 32%;
  }
  @media (max-width: 576px) {
    flex-basis: 36%;
  }
}

.date-edit-delete-accordion .edit-icon {
  position: static;
  @media (min-width: 1920px) {
    font-size: 1.125rem;
  }
}
.date-edit-delete-accordion p {
  padding-top: 0;
  @media (min-width: 1920px) {
    font-size: 1.125rem;
  }
  @media (max-width: 576px) {
    font-size: 0.8rem;
  }
}
.billing-address-cp {
  font-size: 0.875rem;
  @media (min-width: 1920px) {
    font-size: 1rem;
  }
}
.addcard-container .dashboard-section {
  display: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.addcard-container {
  .admin-dashboard {
    width: 250px;
  }
}
.custom-accordion {
  .MuiAccordionSummary-root {
    @media (max-width: 576px) {
      padding: 0px 5px !important;
    }
  }
  .MuiAccordionSummary-root.Mui-expanded {
    @media (max-width: 768px) {
      min-height: 50px;
    }
  }
  .MuiCollapse-wrapper {
    @include theme-aware("background", "lighten-1");
  }
}

.amount-schedule {
  margin-bottom: 15px;
  font-weight: $font-medium;
  @media (min-width: 1280px) {
    font-size: 1.2rem;
  }
}
.credit-card-container .dashboard-content {
  margin: 0 auto;
  margin-top: 60px;
  min-height: 100%;
  background-color: $light-orchid !important;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  border-radius: 8px;

  .dashboard-content-card {
    box-shadow: none !important;
    margin-left: 0 !important;
  }

  .dashboard-content-card.e-check-bg {
    background-color: transparent !important;
    box-shadow: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}
.admin-dashboard .dashboard-section .credit-card-container .dashboard-content .dashboard-content-card {
  box-shadow: none !important;
}
.user-dropdown-img .dropdown-menu {
  padding: 0;
  max-height: 122px;
  overflow: auto;
}
.admin-col-3 {
  margin-bottom: 20px;
}
.admin-col-4 {
  flex: auto;
  max-width: 100%;
}

.user-lightgray-btn {
  color: $black !important;
  background-color: $very-light-gray !important;
  border-color: $very-light-gray;
}

.user-lightgray-btn:hover {
  color: $white !important;
  background-color: $ocean-boat-blue !important;
  border-color: $ocean-boat-blue;
}

.user-lightgray-btn:active {
  color: $white !important;
  background-color: $ocean-boat-blue !important;
  border-color: $ocean-boat-blue !important;
}

.user-lightgray-btn:focus {
  color: $white !important;
  background-color: $ocean-boat-blue !important;
  border-color: $ocean-boat-blue !important;
}

.user-secure-img:hover {
  background-color: $ocean-boat-blue;
}

.user-img-box {
  display: flex;
}
.user-img-box .dropdown-item {
  padding: 10px;
}

.user-register-img {
  width: 40px;
}

.admin-dropdown-img .dropdown-menu {
  padding: 0;
  max-height: 122px;
  overflow: auto;
}

.user-img {
  margin-left: 10px;
  height: 37px;
  width: 37px;
}
.admin-img-box {
  display: flex;
}
.admin-img-box .dropdown-item {
  padding: 10px;
}

.admin-register-img {
  width: 40px;
}

.admin-secure-img:hover {
  background-color: $ocean-boat-blue;
}

.admin-dropdown-img .dropdown-menu {
  padding: 0;
  max-height: 122px;
  overflow: auto;
}
.admin-img {
  margin-left: 10px;
}
.selectdropdown {
  padding: 1.15rem 0 !important;
  .MuiList-root-MuiMenu-list {
    padding: 6px 16px !important;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
//Image-Tooltip//
.css-7malf3-MuiTooltip-tooltip,
.css-aprbe4-MuiTooltip-tooltip,
.css-rtrd29-MuiTooltip-tooltip {
  padding: 2px 5px !important;
  background-color: transparent !important;
}
.toolTip {
  padding: 2px 5px !important;
  background-color: transparent !important;
}
.image-tooltip {
  width: 450px;
  @media (max-width: 768px) {
    width: 310px !important;
  }
  @media (max-width: 480px) {
    width: 200px !important;
  }
}
.cvv-tooltip {
  width: 250px;
}
//Image-Tooltip//
.selectpageno {
  select {
    border: none !important;
  }
}
.custom-pagination {
  padding: 0 10px 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 480px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  .selectpageno {
    margin: 0px;
    font-weight: $font-regular;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: inherit;
    margin-right: 15px;
  }
  .arrow-icons {
    color: rgba(0, 0, 0, 0.6) !important;
    cursor: pointer;
  }
  .arrow-icons-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    background-color: transparent !important;
  }
}

.security-image-dropdown {
  .MuiOutlinedInput-input {
    padding: 16.5px 14px 15px 0;
  }
}

.MuiPopover-paper {
  position: absolute;
  max-height: 250px !important;
}

//Global-default-readonly-fields//
.label {
  font-weight: $font-medium;
  color: #666;
}
.value {
  font-weight: $font-semiBold;
  color: #333;
}
//Global-default-readonly-fields//

//autocomplete//
.echeck-date-picker {
  white-space: pre;
}
//check-box-color-when-checked//

input[type="checkbox"] {
  @include theme-aware("accent-color", "linear-2");
}
.MuiCheckbox-root {
  @include theme-aware("accent-color", "linear-2");
}
//check-box-color-when-checked//

//helper-text

.member-details {
  .ssn-helper {
    @media (max-width: 475px) {
      margin-bottom: 30px !important;
    }
  }
}
