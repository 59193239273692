.profile-section {
  padding: 15px 35px;
  text-align: left;

  h1 {
    margin: 20px 0;
    color: $darkblue !important;
    font-size: 1rem;
    font-weight: 500;
  }
  .input-field {
    border-radius: 8px;

    .myprofile-form {
      background: $white;
      padding: 24px 16px;
      border-radius: 7px;
      box-shadow: 0px 3px 6px #00000029 !important;
    }
  }
  .verified-input {
    color: $green;
    font-size: 0.875rem;
    font-style: italic;
    padding-right: 5px;
  }
  .profile-data {
    border-bottom: 1px solid $secondary-color;
    padding-bottom: 20px;
  }
}
.logolink img {
  width: 108px;
}
