.download-btn {
  background-color: $white;
  border: 1px solid $yellow-orange;
}

.pay-plan-options {
  @include mainCard;
  background-color: $milky-gray !important;
  margin-top: 0px !important;
  box-shadow: none !important;

  .radio-btns {
    @media (max-width: 767px) {
      display: flex;
      align-items: flex-start !important;
      justify-content: center;
      flex-wrap: wrap;
    }
    @media (max-width: 767px) {
      padding: 0 8px;
    }
    .MuiIconButton-label {
      .MuiSvgIcon-root {
        fill: $darkenblue;
      }
    }
    .pay-monthly {
      .MuiFormControlLabel-root {
        margin-left: 8px;
      }
    }
    .MuiFormControlLabel-root {
      background-color: $white;
      border-radius: 10px;
      border: 1px solid $yellow-orange;
      @media (max-width: 767px) {
        min-width: 193px;
        margin-left: 5px;
      }
    }
    .MuiFormControlLabel-label {
      font-size: 0.875rem;
      padding-right: 20px;
      font-family: inherit;
      color: $black !important;
    }
  }
  .recurring-plans {
    padding: 20px 0 0;
    .MuiInputAdornment-root {
      p {
        padding-left: 14px !important;
      }
    }
  }

  .submit-cancel-btns {
    margin: 0 15px;
  }
}
.payplan-options {
  .form-group {
    white-space: pre;
  }
}
.pay-hospital-bill {
  .total-amount {
    @media (max-width: 425px) {
      padding-left: 20px;
    }
  }
  .accordion-header {
    .MuiAccordionSummary-content {
      font-size: 0.8125rem;
      font-weight: 600;
      color: $oxfordblue;
      justify-content: space-between;
    }
  }
  .accordion-table {
    .MuiTableCell-root {
      color: $oxfordblue;
      border: none !important;
      padding: 8px 4px !important;
      font-family: "poppins";
    }
    .outstanding-amount {
      font-size: 0.9375rem;
      font-weight: 600;
    }
  }

  .hospital-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 250px;
    }
  }
  .MuiTableCell-head {
    color: $oxfordblue;
    border: none !important;
    padding: 8px 4px !important;
    font-family: "poppins";
  }
}

.settile-account-modal {
  padding: 24px;
  h2 {
    padding: 22px 0 30px;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    font-weight: 600;
    color: $black;
  }
  .settile-account-content {
    text-align: center;
    padding: 10px;
    p {
      padding-bottom: 24px;
    }
  }
}
.settlement-offer {
  padding: 10px 0px;

  .settlement-offer-details {
    background-color: aliceblue;
    margin: 0px auto;
    padding: 10px;
    border: 1px solid #388bfd66;
    border-radius: 8px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    width: 97%;
    overflow: auto;
    @media (min-width: 1536px) {
      width: 85%;
    }
    @media (min-width: 1280px) {
      justify-content: center;
    }
    @media (min-width: 768px) and (max-width: 992px) {
      justify-content: center;
    }
  }
  p {
    display: flex;
    align-items: center;
    margin-left: 15px;
    white-space: nowrap;
    @media (max-width: 576px) {
      font-size: 0.875rem;
    }
  }
  p:nth-child(1) {
    font-weight: 500;
    margin-left: 0 !important;
    .amount-settle {
      font-weight: 700;
      font-size: 1rem;
      @media (max-width: 576px) {
        font-size: 0.968rem;
      }
    }
  }
  p:nth-child(2) {
    cursor: pointer;
    color: #0000ff;
    text-decoration: underline;
  }
  p:nth-child(3) {
    font-weight: 500;
    .date-settle {
      font-weight: 700;
    }
  }
  .pay-plan-options {
    padding: 15px !important;
    p {
      margin-bottom: 0;
    }
    .primary-button {
      padding: 10px 0;
    }
    .blink {
      -webkit-animation: glowing 1s infinite;
      -moz-animation: glowing 1s infinite;
      -o-animation: glowing 1s infinite;
      animation: glowing 1s infinite;
    }
    @-webkit-keyframes glowing {
      0% {
        background-color: $azure;
      }
      50% {
        background-color: $primary-color;
      }
      100% {
        background-color: $azure;
      }
    }

    @-moz-keyframes glowing {
      0% {
        background-color: $azure;
      }
      50% {
        background-color: $primary-color;
      }
      100% {
        background-color: $azure;
      }
    }

    @-o-keyframes glowing {
      0% {
        background-color: $azure;
      }
      50% {
        background-color: $gray-purple;
      }
      100% {
        background-color: $azure;
      }
    }

    @keyframes glowing {
      0% {
        background-color: $azure;
      }
      50% {
        background-color: $primary-color;
      }
      100% {
        background-color: $azure;
      }
    }
  }
}

.schedule-payments {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
}
.schedule-pay-plan {
  @media (max-width: 1280px) {
    padding: 20px 10px !important;
  }
}
.dialog-box-styles {
  padding: 20px 10px 10px 10px;
}
.session-expiry-dialog {
  .MuiDialog-paper {
    padding: 15px !important;
  }
}
.session-expiry-box-styles {
  padding: 0px 10px 15px !important;
  text-align: center;
}
