.contact-us-content {
  padding: 0 50px;
  @media (max-width: 1023px) {
    padding: 0 25px;
  }
  @media (max-width: 576px) {
    padding: 0 30px;
  }
  .card {
    padding-bottom: 0 !important;
    border-radius: 4px !important;
    text-align: left;
    @media (max-width: 767px) {
      margin: 10px 0;
    }
    .card-body {
      padding: 10px;
      display: flex;

      .email-icon {
        padding: 0 10px 10px 0;

        i {
          color: $primary-color;
        }
      }

      p {
        color: $darkensilver;
        font-size: 0.8rem;
        margin: 0;

        @media (min-width: 1920px) {
          font-size: 1rem;
        }
      }

      a {
        margin-bottom: 0 !important;
        color: $primary-color;
        font-weight: 500;
        font-size: 0.9rem;
      }
    }
  }
  .form-group {
    span {
      color: $red;
      margin: -3px 0px 0px 2px;
    }
    .MuiOutlinedInput-root {
      border-radius: 8px !important;
    }
    textarea {
      font-size: 0.75rem;
      @media (min-width: 1920px) {
        font-size: 1rem;
      }
    }
  }
  .contact-you {
    padding: 20px 0 16px;

    p {
      font-weight: 600;
      font-size: 0.9375rem;
      color: $lightblue;

      @media (max-width: 767px) {
        font-size: 0.75rem;
      }
    }
  }

  .submit-cancel-btns {
    button {
      padding: 5px 30px;
      margin: 0 10px;
    }
  }
}

.footer-section {
  color: $darkensilver;
  text-align: center;
  margin-top: 10px;
  p {
    color: $white;
    font-size: 14px;
    margin-top: 16px;
    @media (max-width: 767px) {
      font-size: 0.75rem;
    }
  }
}

//--footer-Section--//

.footer-section-cp {
  display: flex;
  // min-height: 100px;
  justify-content: center;
  // align-items: flex-end;
  margin-left: 250px;
  line-height: 1.7;
  @media (max-width: 1024px) {
    margin-left: 0px;
  }
  .footer-cp {
    color: $secondary-color;
    font-size: 0.875rem;
    // margin: 0 auto 0 250px;
    text-align: center;
    // padding: 10px 0px;
    .footer-contactus {
      font-size: 1rem;
      display: flex;
      justify-content: center;
      a {
        padding: 0px 2px;
        text-decoration: none;
      }
    }
  }
}

.footer-section-collapse {
  margin-left: 60px;
  @media (max-width: 1024px) {
    margin-left: 0px;
  }
  .footer-cp {
    color: $secondary-color;
    font-size: 0.875rem;
    // margin: 0 auto 0 250px;
    text-align: center;
    // padding: 10px 0px;
    line-height: 1.7;
    .footer-contactus {
      font-size: 1rem;
      display: flex;
      justify-content: center;
      a {
        padding: 0px 2px;
        text-decoration: none;
      }
    }
  }
}

//--footer-Section--//
.contactus-card {
  padding: 5px;
  .card {
    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
  }
  .contactus-card-body {
    display: flex;
    align-items: baseline;
    padding: 16px;
    @media (max-width: 576px) {
      justify-content: center;
    }

    .email-icon {
      display: flex;
      align-items: baseline;
    }
    .card-text {
      margin-left: 10px;
      p {
        margin-bottom: 8px;
      }
    }
  }
  .contactus-buttons {
    display: flex;
    justify-content: center;

    button {
      margin: 6px;
    }
  }
  .enter-message-field {
    .MuiOutlinedInput-root {
      padding: 0 0 14px 0px !important;
    }
  }
}
