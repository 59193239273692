.MuiTablePagination-displayedRows {
  display: none !important;
}
.payment-recieved-container {
  position: relative;
  .purl-box {
    padding: 20px 0;
  }
  .purl-detail-box {
    display: flex;
    @media (max-width: 767px) {
      justify-content: center;
    }
    .purl-detail-box-1 {
      display: flex;
      width: 50%;
      justify-content: center;
      @media (min-width: 1480px) {
        justify-content: center;
      }
      @media (max-width: 991px) {
        flex-direction: column !important;
        text-align: center;
        width: 50%;
      }
      @media (max-width: 767px) {
        width: 85%;
      }
      .payments-received-header-item {
        font-size: 0.8rem;
        font-weight: 500;
        letter-spacing: 0.5px;
        border: 1px solid #c7c7c7;
        border-radius: 5px;
        padding: 8px;
        margin: 4px;
        background-color: #d5e9ff;
        box-shadow: 1px -1px 3px 0px rgba(212, 212, 212, 1);
        @media (min-width: 1536px) {
          font-size: 1rem;
        }
      }
    }
    .purl-amount {
      @media (max-width: 767px) {
        margin-top: 110px;
      }
    }
    .purl-detail-box-2 {
      @media (min-width: 480px) and (max-width: 767px) {
        position: absolute;
        top: 38%;
      }
      @media (max-width: 480px) {
        position: absolute;
        top: 35%;
      }
    }
  }
  .purl-pie {
    width: 350px;
    height: 350px;
    margin: 0 auto;
    @media (min-width: 1920px) {
      width: 450px;
      height: 450px;
    }
    @media (min-width: 1536px) {
      width: 400px;
      height: 400px;
    }
    @media (max-width: 480px) {
      width: 280px;
      height: 280px;
      margin: 10px auto;
    }
    &.purl-pie-amount {
      @media (max-width: 767px) {
        margin-top: 100px;
      }
      @media (max-width: 480px) {
        width: 300px !important;
        height: 300px !important;
      }
    }
    .apexcharts-legend {
      @media (max-width: 767px) {
        width: 100%;
      }
      .apexcharts-legend-text {
        @media (min-width: 1536px) {
          font-size: 1rem !important;
        }
      }
    }
  }
  .purl-achievement {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 10px 0px;
    width: 50%;
    margin: 20px auto !important;
    border: 1.5px solid #1bc52f;
    font-size: 1.125rem;
    @media (max-width: 992px) {
      width: 70%;
    }
    @media (max-width: 767px) {
      width: 100%;
      position: absolute;
      top: 0;
    }
  }
  .star-icon {
    color: #1bc52f;
    font-size: 25px;
    padding-right: 10px;
  }
}

// purl usage //
.purl-usage-container {
  .purl-box {
    padding: 20px 0;

    .purl-table-wrapper {
      width: 95%;
      box-shadow: 5px 2px 5px 0px rgba(0, 0, 0, 0.37);
      margin: 40px auto 0 auto;
      @media (min-width: 1280px) {
        width: 350px;
      }
      @media (max-width: 767px) {
        width: 60%;
        margin: 20px auto;
      }
      @media (max-width: 600px) {
        width: 80%;
        margin: 20px auto;
      }
      @media (max-width: 480px) {
        width: 95%;
        margin: 20px auto;
      }
      .purl-table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 15px;
        background-color: $azure-white;
        .table-cell-title {
          font-size: 0.9rem;
          font-weight: 600;
          @media (max-width: 992px) {
            font-size: 0.75rem;
          }
        }
        .table-cell-data {
          font-size: 1.125rem;
          color: $carolina-blue;
          @media (max-width: 992px) {
            font-size: 1rem;
          }
        }
      }

      .purl-table-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 15px;
        border: 1px solid $light-gray;
        background-color: $concrete-gray;
      }
      .purl-usage-header {
        padding: 8px 15px;
        background-color: rgb(216, 234, 247);
      }
      .purl-usage-cell {
        width: 116px;
        text-align: right;
      }
      .purl-count {
        font-weight: bold;
        font-size: 0.9rem;
      }
      .purl-usage-count {
        font-size: 1.3rem;
        color: #559dcf;
      }
      .purl-table-cell {
        padding: 8px 0px;
        &:nth-child(1) {
          min-width: 116px;
        }
        &:nth-child(2) {
          width: 116px;
          border-right: 1px solid $light-gray;
          border-left: 1px solid $light-gray;
          text-align: center;
        }
        &:nth-child(3) {
          padding: 0 !important;
        }
      }
    }
  }
}

// purl usage //

/* userData-legend */

.apexcharts-legend {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 10px auto;
}
.apexcharts-legend-series {
  margin: 5px !important;
}
/* skippedSurveyData-legend */

.apexcharts-legend-text {
  margin-left: 5px;
  font-family: "poppins !important";
  font-size: 0.8rem;
  @media (min-width: 1536px) {
    font-size: 1rem;
  }
}

.apexcharts-legend-series {
  display: flex !important;
  margin-top: 5px;
  min-width: 45%;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.8rem;
}

.purl-chart-1 {
  width: 100%;
  @media (min-width: 1536px) {
    width: 70%;
    margin: 0 auto;
  }
}
.purl-chart-2 {
  @media (min-width: 1536px) {
    width: 60%;
  }
}
.purl-tooltip-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}
.purl-tooltip-colorBox {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
