.credit-card-section {
  padding: 0 50px;

  @media (max-width: 1023px) {
    padding: 0 25px;
  }

  h2 {
    font-size: 1.125rem;
    padding: 10px 0 0;
    color: $darken-indigo;

    @media (min-width: 1920px) {
      font-size: 1.5rem;
    }

    @media (max-width: 1023px) {
      padding: 0 0 10px;
    }

    @media (max-width: 767px) {
      padding: 0 0 16px;
    }
  }

  .card-content {
    font-size: 0.875rem;
    color: $darkensilver;
    margin: 0;
    padding: 8px 0;

    @media (min-width: 1920px) {
      font-size: 1.125rem;
    }

    @media (max-width: 767px) {
      line-height: 18px;
      font-size: 0.75rem;
    }
  }

  .date-schedule {
    margin: 0;
    align-items: center;
    justify-content: space-between;

    .form-group {
      text-align: left;
      padding-bottom: 0;
      margin: 0;

      label {
        font-size: 0.875rem;
        color: $lightblue;
      }

      .form-check {
        padding-left: 0 !important;
      }

      .check-label {
        color: $blue-magneta;
        font-size: 0.75rem;
        padding-left: 0 !important;

        @media (min-width: 1920px) {
          font-size: 1.125rem;
        }
      }
    }

    .total-amount {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin: 0;
        padding: 0;
      }

      .total-vlaue {
        color: $primary-color !important;
      }
    }
  }
}

.MuiFormControl-marginNormal {
  margin: 0 !important;
}

.MuiFormControlLabel-label {
  color: $darkgrey !important;
  font-family: "poppins" !important;

  @media (max-width: 767px) {
    font-size: 0.75rem !important;
  }
}

.payment-method-label {
  margin-bottom: 0;

  .MuiFormControlLabel-label {
    font-size: 1rem;
    color: $lighten-black !important;
    font-weight: 500;
  }
}

.payment-method-bg {
  background-color: $white;
}

.phone-number {
  display: flex;
  align-items: center;
  justify-content: center;

  .phone-number-select {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid $gray-silver;

    border-right: 0px;
    padding: 12px 3px;
  }

  .MuiOutlinedInput-root {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }
}
