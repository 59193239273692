.border-buttons {
  background: linear-gradient(243deg, #8b89fd 0%, #5351fb 100%);
  color: $white !important;
  border-radius: 10px;
  min-width: 45px !important;
  min-height: 45px;
  text-transform: none !important;
  margin: 5px 4px !important;
}

.small-radius-btn {
  @extend .border-buttons;
}

.medium-radius-btn {
  @extend .border-buttons;
}

.large-radius-btn {
  @extend .border-buttons;
}

.dark-btn {
  @extend .border-buttons;
  background: linear-gradient(270deg, #00a1ab 50%, #00a1ab 50%);
}

.light-btn {
  @extend .border-buttons;
  background: linear-gradient(270deg, #711671 50%, #711671 50%);
}

.yellow-btn {
  @extend .border-buttons;
  background: linear-gradient(270deg, #008c8c 50%, #008c8c 50%);
}

.green-btn {
  @extend .border-buttons;
  background: linear-gradient(270deg, #3470ae 50%, #3470ae 50%);
}

.red-btn {
  @extend .border-buttons;
  background: linear-gradient(270deg, #274edd 50%, #274edd 50%);
}

.darkenblue-btn {
  @extend .border-buttons;
  background: linear-gradient(270deg, #6418c3 50%, #6418c3 50%);
}

.blue-grey-btn {
  @extend .border-buttons;
  background: linear-gradient(270deg, #040353 50%, #040353 50%);
}
// span {
//   font-size: 0.938rem;
// }

.active-theme {
  // border: 2px dotted white !important;
  // position:absolute;
  svg {
    font-size: 1.3rem;
  }
}
