@import "../mixins/mixins";

.primary-button {
  @include button;
  @include primary_btn;
  .MuiSvgIcon-root {
    @media (max-width: 768px) {
      font-size: 1rem !important;
    }
  }
}
.primary-button-login {
  @include button;
  @include primary_btn;
  margin: 10px;
}
.primary-button-login-label {
  @include button;
  @include primary_btn;
  background-color: $grey-web;
  margin: 10px;
}
.primary-button-login-notactive {
  @include button;
  @include secondary_btn;
  margin: 10px;
}
.continue-payement-btn {
  display: flex;
  justify-content: center;
  padding: 1rem 8rem 1rem;
  @media (min-width: 1440px) {
    padding: 1.5rem 6rem;
  }
  @media (max-width: 767px) {
    padding: 1.5rem 0;
  }
  @media (max-width: 1280px) {
    display: flex;
    justify-content: center;
  }
}
.secondary-button {
  @include button;
  @include secondary_btn;
  .MuiSvgIcon-root {
    @media (max-width: 768px) {
      font-size: 1rem !important;
    }
  }
}
.outlined-btn {
  @include button;
  @include outlined_btn;
  @media (min-width: 1920px) {
    font-size: 1rem;
  }
  @media (max-width: 767px) {
    margin: 10px 0;
  }
  @media (max-width: 576px) {
    padding: 0.425em 1.2rem;
    white-space: nowrap;
  }
}
.save-btn {
  background: $primary-color 0% 0% no-repeat padding-box;
  color: $white;
  box-shadow: 0px 3px 13px $skylighten-blue;
}
.cancel-btn {
  background: $secondary-color 0% 0% no-repeat padding-box;
  color: $black;
  box-shadow: 0px 3px 13px $skylighten-blue;
}
.proceed-cancel-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  button {
    margin: 0.38rem;
  }
}
.user-cancel-buttons {
  text-align: center;
  display: flex;
  justify-content: left;
  @media (max-width: 576px) {
    justify-content: space-between;
  }
  button {
    margin: 0.38rem 0.625rem;
    @media (max-width: 576px) {
      margin: 0.625rem 0;
    }
  }
}
.cancel-submit-buttons {
  margin-bottom: 12px;
  button {
    margin: 0.38rem 0.625rem;
    @media (max-width: 768px) {
      width: 40%;
      margin: 0.625rem;
    }
    @media (max-width: 576px) {
      width: 100%;
      margin: 0.625rem 0;
    }
  }
}
.history-btns {
  text-align: left !important;
  button:nth-child(1) {
    margin-left: 0 !important;
  }
}
.themeing-buttons {
  &.Mui-disabled {
    opacity: 0.7;
    color: #fff !important;
    @include theme-aware("background", "color-background");
  }

  @include theme-aware("background", "color-background");
  @include theme-aware("color", "font-color");
  &:hover,
  &:active,
  &:focus {
    @include theme-aware("background", "color-background");
  }
}

.themeing-secondary-buttons {
  &.Mui-disabled {
    opacity: 0.7;
    color: #fff !important;
    @include theme-aware("background", "linear-1");
  }

  @include theme-aware("background", "linear-1");
  @include theme-aware("color", "font-color");
  &:hover,
  &:active,
  &:focus {
    @include theme-aware("background", "linear-1");
  }
}
.btn {
  @media (max-width: 576px) {
    font-size: 0.75rem;
  }
}
.submit-btn {
  margin-top: 2rem;
}
.terms-btn {
  color: #0000ff;
  border: none;
  outline: none;
  background-color: transparent;
  @media (max-width: 576px) {
    text-align: justify !important;
    padding: 0 !important;
  }
}
.cancel-save-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 576px) {
    justify-content: center;
  }
}
.payment-history-btns {
  display: flex;
  justify-content: flex-end;
  @media (max-width: 576px) {
    justify-content: center;
  }
}

.reset-lock {
  position: absolute;
  font-size: 0.6rem !important;
  top: 15px;
  left: 7px;
}

.reset-icon {
  position: relative;
}

.reset-replay {
  font-size: 1.5rem !important;
}

.icon-grey {
  color: #666666 !important;
}
.terms-btn {
  color: #0000ff;
  border: none;
  outline: none;
  background-color: transparent;
}

.MuiTooltip-tooltip.css-aprbe4-MuiTooltip-tooltip {
  margin: 0px !important;
  background-color: rgba(97, 97, 97, 0.92) !important;
}

.contact-btn {
  color: $azure;
  font-size: 0.875rem;
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:hover {
    color: $true-blue;
  }
}
.enter-key-btn {
  cursor: pointer;
  color: $azure;
  font-weight: 600;
  background-color: transparent;
  border: none;
  &:hover {
    background-color: $azure-light;
    border-radius: 5px;
  }
  @media (max-width: 576px) {
    margin-bottom: 12px;
  }
}
.close-btn {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
.report-btns {
  display: flex;
  justify-content: flex-end;
  margin: 16px 0 0;
  @media (min-width: 1920px) {
    margin: 20px 0 0;
  }
  @media (max-width: 576px) {
    justify-content: center;
  }
}

.login-continue-btn {
  justify-content: center !important;
  .MuiButton-label {
    font-size: 0.75rem !important;
    font-weight: $font-semiBold;
  }
  .MuiButton-root {
    min-width: 95px !important;
  }
  .continue-btn {
    padding: 7px 11px;
  }
}

.disabled-btn {
  border: 1px solid #999999 !important;
  background-color: #cccccc;
  color: #666666 !important;
}
