.forgot-password {
  margin-top: 10px;
  padding: 0;
  @media (max-width: 767px) {
    margin-top: 0;
  }
  p {
    color: $lightblue;
    font-weight: 500;
    font-size: 17px;
    margin-bottom: 0;
    padding: 16px 0;
    border-radius: 0 0 1.875rem 1.875rem;
    @media (max-width: 767px) {
      font-size: 0.75rem;
      padding: 10px 12px;
    }
    @media (max-width: 1280px) {
      font-size: 0.8125rem;
      padding: 8px 0;
    }
    a {
      color: $primary-color;
      text-decoration: underline;
      font-size: 1.0625rem;
      @media (max-width: 767px) {
        font-size: 0.75rem;
      }
      @media (max-width: 1280px) {
        font-size: 0.8125rem;
      }
    }
  }
}

.inputFieldDisable {
  background-color: rgba(143, 143, 143, 0.12) !important;
}
.inputFieldProps {
  background-color: transparent !important;
}
.imageLoder {
  display: flex;
  justify-content: center;
}
