@font-face {
  font-family: "Roboto-Light";
  src: url("../../app/fonts/Roboto-Light.ttf");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("../../app/fonts/Roboto-Regular.ttf");
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("../../app/fonts/Roboto-Medium.ttf");
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("../../app/fonts/Roboto-Bold.ttf");
}
// @font-face {
//   font-family: "Poppins-Light";
//   src: url("../../app/fonts/Poppins-Light.otf");
// }
// @font-face {
//   font-family: "Poppins-Regular";
//   src: url("../../app/fonts/Poppins-Regular.otf");
// }
// @font-face {
//   font-family: "Poppins-Medium";
//   src: url("../../app/fonts/Poppins-Medium.otf");
// }
// @font-face {
//   font-family: "Poppins-SemiBold";
//   src: url("../../app/fonts/Poppins-SemiBold.otf");
// }
// @font-face {
//   font-family: "Poppins-Bold";
//   src: url("../../app/fonts/Poppins-Bold.otf");
// }
