.dashboard-content {
  min-height: 100%;

  .quickpay-header {
    background-color: $gray-purple;
    padding: 20px;
    height: 40px;

    img {
      background-color: $white;
      padding: 10px;
      border-radius: 10px;
      width: 120px;
      top: 16px;
      border: 1px solid $gray-purple;
      left: 10%;
      position: absolute;
      z-index: 999;
    }
  }

  .proceed-cancel-buttons {
    margin-bottom: 0 !important;
  }
}

.accordion-card {
  background-color: $white !important;
  border-radius: 10px;

  .MuiAccordion-root {
    padding: 10px 0 !important;

    &.MuiPaper-elevation1 {
      box-shadow: none !important;
    }

    .MuiButtonBase-root {
      padding: 0;

      .MuiAccordionSummary-expandIcon {
        .MuiSvgIcon-root {
          font-size: 1.5625rem !important;
          color: $darken-indigo !important;
        }
      }

      .MuiAccordionSummary-content {
        padding: 0 40px;

        h6 {
          color: $darken-indigo !important;
          font-size: 1.25rem !important;
          font-weight: 600;

          @media (min-width: 1920px) {
            font-size: 1.625rem;
          }
        }
      }

      .accrodian-details {
        padding: 10px 30px;
      }

      .MuiCollapse-container {
        padding: 25px !important;
      }
    }

    .MuiAccordionDetails-root {
      padding: 0 40px;
    }
  }
}

.payment-details {
  width: 100%;
  display: flex;
  padding-bottom: 10px;

  .heading1 {
    flex-basis: 48%;
    flex-shrink: 0;
    text-align: left;
    font-size: 1.0625rem;
    font-weight: 600;
    @media (min-width: 1920px) {
      font-size: 1.25rem;
    }
    @media (max-width: 768px) {
      font-size: 1rem;
      flex-basis: 55%;
    }

    @media (max-width: 576px) {
      font-size: 0.8rem;
      flex-basis: 60%;
    }
  }

  .heading2 {
    flex-basis: 20%;
    font-size: 1.0625rem;
    font-weight: 600;
    @media (min-width: 1920px) {
      font-size: 1.25rem;
    }
    @media (max-width: 768px) {
      margin-left: 35px;
      flex-basis: 25%;
      font-size: 1rem;
    }

    @media (max-width: 576px) {
      font-size: 0.8rem;
      margin-left: 0px;
    }
  }
  div:nth-child(3) {
    @media (max-width: 576px) {
      text-align: right;
    }
  }
}

.payment-options {
  padding: 10px 0px !important;

  .add-icon {
    font-size: 14px;
  }
}

.payment-methods {
  padding: 5px 20px;
  text-align: left;
  display: flex;
  align-items: center;

  h5 {
    font-size: 1rem;
    padding: 12px 0;
    border-bottom: 1px solid $silver;
  }

  .credit-debit-section {
    h6 {
      font-size: 1rem;
      padding: 12px 0;
    }

    .MuiFormControl-root {
      width: 100% !important;
    }

    img {
      padding: 15px 0;
    }
  }
}

.csapayheader .navbar-expand .navbar-nav .dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}
.admin-main-header {
  box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 30%);
  padding: 0.5rem !important;

  @media (max-width: 576px) {
    flex-wrap: wrap-reverse;
  }
  .navbar-nav {
    @media (max-width: 576px) {
      order: 2;
    }
  }
  .logout-link {
    @media (max-width: 576px) {
      order: 3;
    }
  }
  .warning-message-card {
    @media (max-width: 576px) {
      order: 1;
      width: 100%;
    }
  }
  .logolink {
    img {
      width: 110px;
      @media (max-width: 768px) {
        width: 75px;
      }
    }
  }
}
.qp-homepage-container {
  background-color: #f3f3f3;
  min-height: calc(100vh - 165px);
}
.qp-stepper {
  .custom-qp-stepper {
    padding: 20px !important;
    margin: 0px auto !important;
  }
}
