.overlay-container {
  display: block;
  position: fixed;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  top: 0px;
  left: 0px;
  text-align: center;
}
.spinner-border.text-warning {
  margin-top: 20%;
}

/* .red-text {
  color: red;
  font-weight: 600;
  font-weight: normal;
} */
/* .legal-text,
.collection {
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  padding: 5px 10px;
  color: white;
} */
.legal-text {
  color: red;
  font-weight: 600;
}
.collection {
  color: orange;
  font-weight: 600;
}

.billing-pp-cp {
  padding: 20px 10px;
}
.billing-pp-cp table {
  width: 100%;
}
.billing-pp-cp table .bill-payment-amt {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.billing-pp-cp table thead tr td label {
  font-family: "poppinsmedium";
  font-size: 14px;
}

.billing-pp-cp table tbody tr td {
  font-size: 14px;
  padding: 0 5px 0 0px;
  font-family: "poppinssemibold";
}

.billing-pp-cp table tr td:nth-child(2) {
  min-width: 9vw;
}
.billing-pp-cp table tr .chosenamount {
  margin-left: 10px;
  width: 150px;
}
@media (max-width: 1023px) {
  .billing-pp-cp table tr .chosenamount {
    width: 75px;
  }
}
@media (min-width: 1440px) {
  .billing-pp-cp table thead tr td label {
    font-size: 16px;
    font-family: "poppinssemibold";
  }
  .billing-pp-cp table tbody tr td {
    font-size: 16px;
    font-family: "poppinsmedium";
  }
  .billing-pp-cp table tr td:nth-child(2) {
    min-width: 8vw;
  }
}
@media (max-width: 1366px) {
  .billing-pp-cp table thead tr td label {
    font-family: "poppinsmedium";
    font-size: 13px;
  }
}
.disclaimer .MuiAlert-standardWarning {
  color: rgb(102, 60, 0);
  background-color: rgb(255, 244, 229);
  padding: 15px;
  margin-top: 5px;
  font-family: "poppinsregular";
  line-height: 1.5;
}
.billing-pp-cp table tr {
  border: none !important;
}
.billing-pp-cp .accordion-section,
.billing-admin-cp .accordion-section {
  box-shadow: 0px 3px 6px #00000029 !important;
}
.billing-pp-cp .accordion-section .MuiAccordionSummary-content p {
  margin-bottom: 0 !important;
}
@media (max-width: 767px) {
  .pp-payment-table-outstanding tr td div {
    font-size: 13px !important;
  }
  .billing-pp-cp .MuiAccordionDetails-root {
    padding: 8px 5px 16px !important;
  }
}

@media (min-width: 576px) and (max-width: 1023px) {
  .pp-payment-table-outstanding tr td:nth-child(2) div {
    margin-left: 28px;
  }
}

/*  paymentplandetails **/
/* .payPlanDetails-Btn{
  margin-top: 8px;
} */
.cancelBtnStyles {
  padding: 5px;
  background: #fff 0 0 no-repeat padding-box;
  border: 1px solid #0f75bd;
  outline: none;
  border-radius: 3px;
  margin-right: 10px;
}
.editBtnStyles {
  padding: 5px;
  background-color: #0f75bd;
  outline: none;
  border-radius: 3px;
  border: none;
  color: #fff;
  margin-right: 10px;
}
.editPlanBtnStyles {
  padding: 5px 15px;
  background-color: #138496;
  outline: none;
  border-radius: 3px;
  border: none;
  color: #fff;
}

@media (max-width: 1023px) {
  .cancelBtnStyles {
    padding: 3px 10px;
    font-size: 14px;
  }
  .editBtnStyles {
    padding: 3px 4px;
    min-width: 95px;
    font-size: 14px;
  }
}
@media (max-width: 1280px) {
  .cancelBtnStyles,
  .editBtnStyles,
  .editPlanBtnStyles {
    font-size: 13px;
  }
}
@media (max-width: 1200px) {
  .cancelBtnStyles {
    padding: 0px 3px;
    min-width: 135px;
  }
  .editBtnStyles {
    padding: 3px 2px;
    min-width: 105px;
  }
}
@media (max-width: 576px) {
  .cancelBtnStyles {
    padding: 2px 3px;
    width: 100%;
    margin-right: 0;
    margin-bottom: 8px;
  }
  .editBtnStyles {
    padding: 3px 2px;
    width: 100%;
    margin-right: 0;
    margin-bottom: 8px;
  }
  .editPlanBtnStyles {
    padding: 3px 2px;
    margin-bottom: 8px;
    width: 100%;
  }
  .billing-admin-cp .payPlanDetails-Btn {
    justify-content: space-evenly;
    flex-direction: column;
    padding: 0 !important;
  }
}

.forgot-password-cp {
  padding: 0 !important;
  margin: 0 auto;
  max-width: 300px;
}
.forgot-password-cp .admin-button-box {
  max-width: 285px;
  margin: 0 auto;
}

.payment-receipt-content-cp .progressbar {
  display: flex;
  justify-content: space-around;
}
.admin-bill-details .bill-amount {
  white-space: pre;
}
.cards .showcard {
  margin-left: 10px;
  margin-right: 5px;
}
