@import "../mixins/mixins";
.admin-dashboard {
  background-color: $concrete-gray;
  .dashboard-header {
    @include linear-gradient-theme("background", 273deg, 0%, 100%, "linear-1", "linear-2");
    padding: 2px 16px;
    background-color: $primary-color;
    min-height: 58px;
    position: fixed;
    top: 0px;
    z-index: 99;
    width: calc(100% - 250px);
    margin-left: 250px;
    -webkit-box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 30%);
    -moz-box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 30%);
    box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 30%);
    @media (max-width: 767px) {
      width: calc(100% - 200px);
      margin-left: 245px;
    }

    ul {
      margin-bottom: 0;
      padding-left: 0;
      li {
        list-style-type: none;
        cursor: pointer;
        svg {
          fill: $white;
        }
      }
    }
    .select-language {
      .MuiIconButton-label {
        label {
          font-size: 0.875rem;
          color: $white;
          padding: 0 10px;
          @media (max-width: 767px) {
            display: none;
          }
        }
      }
    }
  }

  .dashboard-header-collapse {
    @extend .dashboard-header;
    width: calc(100% - 50px);
    margin-left: 50px;
    @media (max-width: 1170px) {
      margin-left: 0;
      width: 100%;
    }

    ul {
      padding-left: 0;
    }
  }

  .sidenav-section {
    width: 250px;
    background-color: $warm-gray;
    position: fixed;
    height: 100vh;
    z-index: 100;
    top: 0px;
    @media (max-width: 1170px) {
      z-index: 999;
    }
    .logo-section {
      @include theme-aware("background", "color-background");
      text-align: center;
      height: 58px;
      min-height: 40px;
      padding: 24px 0;
      margin-bottom: 55px;
      border-right: 1px solid $darkgrey;

      img {
        background-color: $white;
        border-radius: 7px;
        background-color: $white;
        border: 1px solid $primary-color;
        width: 120px;
        padding: 20px 15px;
      }
      .med-logo {
        max-width: 55px;
        padding: 20px 15px;
      }
    }

    .profile {
      padding: 20px 0px;
      background-color: $white-cultured;
      margin-top: 88px;
      min-height: 90px;
      max-height: 90px;
      text-align: center;
      @media (max-width: 767px) {
        padding: 10px;
      }

      img {
        width: 60px;
      }
      .profile-title {
        display: flex;
        flex-direction: column;
        line-height: 1.5px;
        color: $oxfordblue;
        h6 {
          font-size: 0.875rem;
          padding: 15px 5px;
        }
        p {
          font-size: 0.75rem;
          padding-left: 5px;
        }
      }
      svg {
        font-size: 2.1875rem;
        padding-left: 10px;
      }
    }
    .nav-links {
      display: flex;
      flex-direction: column;
      padding-top: 16px;
      max-height: calc(100vh - 250px);
      overflow-x: hidden;
      overflow-y: auto;
      scroll-behavior: smooth;
      /* width */
      &::-webkit-scrollbar {
        width: 5px;
        border-radius: 6px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $gray-silver-shade;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $gray-silver;
      }

      a {
        @include theme-aware("color", "linear-2");
        font-size: 0.875rem;
        text-decoration: none;
        color: $oxfordblue;
        padding: 8px 8px 10px 16px;
        display: flex;
        width: 250px;
        align-items: center;
        margin-bottom: 0.2rem;
        &:last-child {
          margin-top: auto;
        }
        &:hover,
        &.active {
          @include linear-gradient-theme("background", 243deg, 0%, 100%, "linear-1", "linear-2");
          color: $white !important;
        }
        &:active {
          background-color: $white-cultured;
          padding: 8px 8px 10px 16px;
        }
        svg {
          font-size: 1.5rem;
          margin-bottom: 5px;
          margin-right: 10px;
        }
      }
      .resend-verification {
        white-space: pre !important;
      }
      .logout-link {
        position: fixed;
        bottom: 0px;
        min-width: 250px;
      }
      .logout-collapsed {
        position: fixed;
        bottom: 0px;
        min-width: 0px;
      }
    }
  }

  .sidenav-section-collapse {
    @extend .sidenav-section;
    width: 60px !important;
    transition: all 0.8s ease;
    @media (max-width: 1170px) {
      margin-left: -60px;
    }
    &:hover {
      width: 250px !important;
      text-align: left;
      transition: all 0.8s ease;
      .d-none-label {
        span {
          display: block;
        }
      }
      .med-logo {
        max-width: 120px;
        transition: all 0.8s ease;
      }
      .link-item {
        display: flex;
        align-items: center;
      }
      .logout-collapsed {
        min-width: 250px;
      }
    }
  }
  .admin-dashboard-section {
    min-height: calc(100vh - 60px) !important;
  }
  .dashboard-section {
    min-height: calc(100vh - 136px);
    margin-left: 250px;
    margin-top: 60px;
    @media (max-width: 1170px) {
      width: 100vw;
      margin-left: 0;
    }

    .dashboard-content {
      padding: 20px;
      .changepw-bg,
      .dashboard-content-card {
        @include mainCard;
        @media (max-width: 576px) {
          padding: 20px 10px;
        }

        .total-amount {
          display: flex;
          justify-content: space-between;
          align-items: center;
          p {
            margin: 0;
            padding: 0;
          }
          .total-vlaue {
            color: $primary-color !important;
            font-size: 1rem;
            font-weight: 600;
            padding-left: 30px;
          }
        }
      }
      .cancel-plans {
        .secondary-button {
          padding: 10px 16px;
        }
      }
      .quickpay-payment {
        @media (max-width: 576px) {
          padding: 0;
        }
        .thank-you h2 {
          @media (max-width: 767px) {
            margin-bottom: 20px;
          }
          @media (max-width: 576px) {
            font-size: 1.375rem;
          }
        }
      }
    }
  }

  .content-title {
    color: $black;
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 16px;
    svg {
      font-size: 1.65rem;
      margin-bottom: 5px;
      margin-right: 8px;
      color: $primary-color;
    }
    p {
      color: $primary-color;

      font-size: 1.875rem;
      font-weight: 300;
      text-align: left;
      padding: 30px 16px 10px;

      .user-name {
        font-size: 1.875rem;
      }
    }
  }
}

.dashboard-section-collapse {
  @extend .dashboard-section;
  margin-left: 60px !important;
  @media (max-width: 1170px) {
    width: 100vw;
    margin-left: 0px !important;
  }
  .dashboard-header {
    width: calc(100% - 50px) !important;
    @media (max-width: 1023px) {
      width: calc(100% - 0px) !important;
    }
  }
}

.d-none-label {
  span {
    display: none;
  }
}
.report-active-link {
  &:active {
    @include linear-gradient-theme("background", 243deg, 0%, 100%, "linear-1", "linear-2");
    padding: 8px 8px 10px 16px;
  }
}

//customicons

.custom-icons {
  width: 1.5rem;
  height: 1.5rem;
  fill: currentColor;
}

.icon-heading-styles {
  width: 2rem;
  height: 2rem;
  fill: currentColor;
  @media (max-width: 767px) {
    width: 1.7rem;
    height: 1.7rem;
  }
}

//customicons

//scheduled-icons

.scheduled-icons {
  position: relative;
  .scheduled-dollar {
    position: absolute;
    font-size: 1.1rem !important;
    left: 4px;
    top: 6px;
  }
}
.scheduled-heading-icons {
  position: relative;
  .scheduled-heading-dollar {
    position: absolute;
    font-size: 1.1rem !important;
    left: 5px;
    top: 13px;
    @media (min-width: 601px) and (max-width: 768px) {
      top: 9px;
    }
    @media (max-width: 600px) {
      top: 7px;
    }
  }
}
//scheduled-icons
