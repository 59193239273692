.landing-page-form {
  fieldset {
    .MuiFormHelperText-root {
      font-size: 0.8rem;
      text-align: center;
    }
  }
}

.required:after {
  content: " *";
  color: $red;
}

.copy-right {
  text-align: center;
  p {
    color: $white;
    font-size: 14px;
    margin-top: 16px;
    @media (max-width: 767px) {
      font-size: 0.75rem;
    }
  }
  a {
    color: $white;
  }
  p:nth-child(1) {
    margin-bottom: 25px !important;
    .contactus-link {
      color: $primary-color;
      text-decoration: underline;
    }
  }
  p:nth-child(2) {
    margin-bottom: 0px !important;
  }
  p:nth-child(3) {
    margin-top: 10px !important;
  }
  .contactus-link a {
    color: $primary-color;
    text-decoration: underline;
  }
}
.admin-logout {
  padding-top: 6px;
  color: $red;
  font-size: 20px;
  cursor: pointer;
}

.admin-logout:hover {
  padding-top: 6px;
}
