// @import '../utils/fonts';

// * {
//   font-size: 16px;
//   padding: 0;
//   margin: 0;
//   font-family: $font-regular;
// }
@import "../utils/colors";
html,
body {
  font-size: 16px;
  font-family: "Poppins" !important;
  color: $oxfordblue;
  font-weight: 400;
  background-color: $white;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  overscroll-behavior: contain;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-weight: normal;
  margin: 0;
  background: none;
  line-height: 1.5;
  font-family: "Poppins";
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1.875rem;
}
h4 {
  font-size: 1.625rem;
}
h5 {
  font-size: 1.5rem;
}
h6 {
  font-size: 1.25rem;
}

a:hover {
  color: #086bd6;
  text-decoration: underline;
}
button {
  cursor: pointer;
}
button:disabled {
  cursor: revert;
}
.MuiTypography-root,
.MuiTab-wrapper,
.MuiButton-label {
  font-family: "poppins" !important;
}
.Toastify__toast--warning {
  color: $brown !important;
  background-color: $white-brown !important;
  border-color: $white-brown;
  @media (min-width: 1920px) {
    font-size: 1.125rem;
  }
  @media (min-width: 768px) {
    min-width: 400px;
  }
}
.Toastify__close-button {
  color: $brown !important;
}
.Toastify__toast--success,
.Toastify__toast--error {
  @media (min-width: 1920px) {
    font-size: 1.125rem;
  }
  .Toastify__close-button {
    color: $white !important;
    opacity: 1;
  }
}
