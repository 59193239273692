.payment-schedule-section {
  h2 {
    font-size: 2rem !important;
    color: $primary-color;
    line-height: 2;

    @media (max-width: 1366px) {
      font-size: 1.875rem !important;
    }
    @media (max-width: 767px) {
      line-height: 1.3;
      font-size: 1.25rem !important;
    }
  }
  h3 {
    color: $yellow-orange;
    font-size: 1.125rem;
    font-weight: 600;
    @media (max-width: 1366px) {
      font-size: 1.125rem;
    }
    @media (max-width: 767px) {
      margin-top: 0.625rem;
      padding: 10px 20px 0;
      font-size: 0.875rem;
    }
  }
  .payments {
    margin: 32px 0 32px 24px;
    @media (max-width: 1366px) {
      margin: 29px 0 16px;
    }
    @media (max-width: 767px) {
      margin: 16px 0;
    }
    .success-greetings {
      color: $lightblue;
      font-size: 1.125rem;
      margin: 0;
      line-height: 1.5;
      @media (max-width: 1366px) {
        font-size: 1rem;
      }
      @media (max-width: 767px) {
        font-size: 0.875rem;
      }
    }
  }
}
