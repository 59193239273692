.quickpay-payment {
  padding: 0px 25px 40px;
  margin-top: 20px;
  .payment-receipt {
    border: 1px solid $gray-silver;
    padding: 24px 13px;
    border-radius: 5px;
    h4 {
      font-size: 1.25rem;
      color: $lighten-black;
      line-height: 1.5;
      padding-bottom: 16px;
      font-weight: 600;
      @media (min-width: 1920px) {
        font-size: 1rem;
      }
    }
    p {
      font-size: 0.75rem;
      text-align: left;
      margin: 0;
      color: $black;
      line-height: 1.9;
      @media (min-width: 1920px) {
        font-size: 0.825rem;
      }
    }
    p:nth-child(2) {
      color: $lighten-black;
      font-weight: 600;
    }
    .success {
      color: #17b7bd !important;
    }
    .download-button {
      margin-top: 15px;
      background-color: $yellow-orange;
      padding: 8px 60px;
      color: $white !important;
      border-radius: 5px;
      border: 0;
      outline: 0;
      font-size: 0.8125rem;
      @media (min-width: 1920px) {
        font-size: 0.875rem;
        padding: 6px 43px;
      }
    }
    .receipt-section {
      border-top: dotted;
      border-bottom: dotted;
      border-bottom-color: $darkgrey;
      border-top-color: $darkgrey;
      margin: 0 4px;
      div {
        margin: 7px 0;
        text-align: left;
        p {
          white-space: pre-wrap;
        }
      }
      div:nth-child(even) {
        p {
          font-weight: 600;
        }
      }
      .payment-day {
        font-weight: 500;
      }
    }
  }
  .thank-you {
    h3 {
      color: $yellow-orange;
      font-size: 1.5rem;
      font-weight: 600;
      @media (min-width: 1920px) {
        font-size: 1.625rem;
        line-height: 1.5;
      }
    }
    h2 {
      color: $yellow-orange;
      font-size: 30px;
      font-weight: 600;
    }
    p {
      font-size: 0.75rem;
      margin: 0;
      line-height: 1.5;
      font-size: 1rem;
      color: $lightblue;

      @media (min-width: 1920px) {
        font-size: 1rem;
        line-height: 1.5;
      }
    }
    p:nth-child(3) {
      margin-top: 50px;
    }
    .sign-up-button {
      border: 0;
      outline: 0;
      background-color: $primary-color;
      color: $white;
      font-size: 1rem;
      padding: 10px 35px;
      border-radius: 7px;
      margin-top: 12px;
      @media (min-width: 1920px) {
        border-radius: 13px;
        padding: 13px 60px;
        font-size: 1.125rem;
        margin-top: 12px;
      }
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
    }
  }
}
//dashboard pay now,pay future success screens css
.success-card {
  padding: 0 !important;
  margin-top: 60px;
  .quickpay-payment {
    padding: 0;
    border-radius: 8px;
    position: relative;
    .payment-receipt {
      padding: 40px 40px 30px 70px;
      text-align: center;
      border: none !important;
      @media (max-width: 1023px) {
        padding: 40px 0px 30px 51px;
      }
      @media (max-width: 767px) {
        padding: 40px 40px 30px 40px;
      }
      @media (max-width: 576px) {
        padding: 52px 20px 30px;
      }
      @media (max-width: 425px) {
        padding: 52px 0 30px;
      }
      h3 {
        border-bottom: none !important;
        font-size: 1.25rem;
        color: $lighten-black;
        line-height: 1.5;
        padding-bottom: 16px;
        text-align: center;
        font-weight: 600;
        @media (min-width: 1920px) {
          font-size: 1.25rem;
        }
        @media (max-width: 767px) {
          margin-top: 15px;
        }
      }
      p {
        font-size: 14px;
        text-align: left;
        margin: 0;
        color: $black;
        line-height: 1.9;
        @media (min-width: 1920px) {
          font-size: 0.825rem;
        }
      }
      p:nth-child(2) {
        color: $lighten-black;
        font-weight: 600;
      }
      .success {
        color: $green !important;
      }
      .download-button {
        margin-top: 22px;
        background-color: $primary-color !important;
        padding: 8px 36px !important;
        color: $white;
        border-radius: 5px;
        border: 0;
        outline: 0;
        font-size: 0.8125rem;
        @media (min-width: 1920px) {
          font-size: 0.875rem;
          padding: 6px 43px;
        }
      }
      .receipt-section {
        border-top: dotted;
        border-bottom: dotted;
        border-bottom-color: $darkgrey;
        border-top-color: $darkgrey;
        padding: 16px 0;
        div {
          margin: 7px 0;
        }
        div:nth-child(even) {
          p {
            font-weight: 600;
            text-align: right;
            @media (max-width: 425px) {
              text-align: left;
            }
          }
        }
        .payment-day {
          font-weight: 500;
        }
      }
    }
    .thank-you {
      background-color: $tan-green;
      // padding: 150px 100px 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      text-align: center;
      @media (max-width: 1023px) {
        padding: 150px 50px 150px;
      }
      @media (max-width: 767px) {
        padding: 150px 50px;
      }
      @media (max-width: 767px) {
        padding: 27px 30px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 0;
      }

      h3 {
        color: $yellow-orange;
        font-size: 1.5rem;
        font-weight: 600;
        align-self: center;
        @media (min-width: 1920px) {
          font-size: 1.625rem;
          line-height: 1.5;
        }
      }
      h2 {
        font-size: 30px;
        font-weight: 500;
        color: $white;
        // padding: 90px 0;
        text-align: center;

        @media (max-width: 1100px) {
          margin-right: 20px;
        }
        @media (max-width: 767px) {
          padding: 0 15px;
        }
      }
      p {
        font-size: 0.75rem;
        margin: 0;
        line-height: 1.5;
        font-size: 1rem;
        color: $lightblue;

        @media (min-width: 1920px) {
          font-size: 1rem;
          line-height: 1.5;
        }
      }
      p:nth-child(3) {
        margin-top: 50px;
      }
      .sign-up-button {
        border: 0;
        outline: 0;
        background-color: $primary-color;
        color: $white;
        font-size: 1rem;
        padding: 10px 36px;
        border-radius: 7px;
        margin-top: 12px;
        @media (min-width: 1920px) {
          border-radius: 13px;
          padding: 13px 60px;
          font-size: 1.125rem;
          margin-top: 12px;
        }
        @media (max-width: 767px) {
          margin-bottom: 30px;
        }
      }
    }
    .success-img {
      position: absolute;
      top: 255px;
      left: 37%;
      background-color: $white;
      border-radius: 50%;
      padding: 8px;
      @media (min-width: 1500px) {
        left: 38%;
        top: 232px;
      }
      // @media (max-width: 1440px) {
      //   left: 89%;
      // }
      @media (max-width: 1023px) {
        left: 36%;
        transform: scale(0.85);
      }
      @media (max-width: 767px) {
        left: 40%;
        top: 65px;
        transform: scale(0.65);
      }
      @media (max-width: 575px) {
        left: 34%;
        top: 65px;
      }
      @media (max-width: 375px) {
        left: 31%;
        top: 84px;
      }
      @media (max-width: 360px) {
        left: 32%;
        top: 87px;
      }

      img {
        border-radius: 44%;
        padding: 7px 0;
      }
    }
  }
  .payment-schedule-section {
    h3 {
      color: $primary-color !important;
    }
  }
}
//quick pay successfull payment screen//
.successful-payment-container {
  justify-content: center;
  display: flex;
  align-items: center;
  height: calc(100vh - 160px);
  padding: 20px;
  @media (max-width: 576px) {
    height: auto;
    flex-wrap: wrap-reverse;
  }
  .payment-banner-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    img {
      height: calc(100vh - 170px);
      @media (max-width: 576px) {
        height: calc(100vh - 320px);
      }
    }
  }
  .scheduled-content {
    width: 50%;
    margin: 0px auto;
    text-align: center;
    @media (max-width: 576px) {
      width: 100%;
    }
    .heading-style {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.5rem;
      color: #320469 !important;
      font-weight: $font-semiBold;
      margin-bottom: 10px;
      text-transform: capitalize;
      @media (max-width: 1024px) {
        font-size: 2rem;
      }
      @media (max-width: 768px) {
        font-size: 1.5rem;
      }
      @media (max-width: 576px) {
        font-size: 1.2rem;
        margin-top: 20px;
      }
    }
    .payment-processed {
      font-size: 1.125rem;
      @media (max-width: 576px) {
        font-size: 1rem;
      }
    }
    p {
      font-weight: $font-semiBold;
      font-size: 1.5rem;
      color: #320469;
      @media (max-width: 1024px) {
        font-size: 1.25rem;
      }
      @media (max-width: 576px) {
        font-size: 1rem;
      }
    }

    .scheduled-buttons {
      min-width: 235px;
      padding: 10px;
      @media (max-width: 576px) {
        min-width: 175px;
      }
    }
  }

  .successfull-payment-card {
    width: calc(100% - 60%);
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: rgb(50 4 105 / 26%) 0px 2px 8px 0px;

    @media (max-width: 768px) {
      width: calc(100% - 35%);
    }
    @media (max-width: 576px) {
      width: calc(100% - 10%);
    }
    .quickpay-payment-content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: #17b7bd;

      .payment-receipt {
        padding: 0px 20px 20px;
        text-align: center;
        @media (max-width: 767px) {
          padding: 20px;
        }
        h5 {
          font-size: 1.25rem;
          color: $lighten-black;
          line-height: 1.5;
          padding-bottom: 16px;
          text-align: center;
          font-weight: 600;
          @media (min-width: 1920px) {
            font-size: 1.25rem;
          }
          @media (max-width: 767px) {
            margin-top: 15px;
          }
        }
        p {
          font-size: 14px;
          text-align: left;
          margin: 0;
          color: $black;
          line-height: 1.9;
          @media (min-width: 1920px) {
            font-size: 0.825rem;
          }
        }
        p:nth-child(2) {
          color: $lighten-black;
          font-weight: 600;
        }
        .success {
          color: $green !important;
        }
        .download-button {
          margin-top: 22px;
          background-color: $primary-color !important;
          padding: 8px 36px !important;
          color: $white;
          border-radius: 5px;
          border: 0;
          outline: 0;
          font-size: 0.8125rem;
          @media (min-width: 1920px) {
            font-size: 0.875rem;
            padding: 6px 43px;
          }
        }
        .receipt-section {
          border-top: dotted;
          border-bottom: dotted;
          border-bottom-color: $darkgrey;
          border-top-color: $darkgrey;
          padding: 16px 0;
          div {
            margin: 7px 0;
          }
          div:nth-child(even) {
            p {
              font-weight: 600;
              text-align: right;
              @media (max-width: 425px) {
                text-align: left;
              }
            }
          }
          .payment-day {
            font-weight: 500;
          }
        }
      }

      .thank-you {
        @include theme-aware("background", "color-background");
        padding: 16px;
        border-radius: 8px 8px 0px 0px;
        text-align: center;
        h3 {
          color: $yellow-orange;
          font-size: 1.5rem;
          font-weight: 600;
          align-self: center;
          @media (min-width: 1920px) {
            font-size: 1.625rem;
            line-height: 1.5;
          }
        }
        h2 {
          font-size: 26px;
          font-weight: 500;
          color: $white;
          text-align: center;
          @media (max-width: 1024px) {
            font-size: 22px;
          }
          @media (max-width: 768px) {
            font-size: 20px;
          }
          @media (max-width: 576px) {
            font-size: 16px;
          }
        }
      }
      .signup-text {
        margin-top: 40px;
        @media (max-width: 767px) {
          margin-top: 50px;
        }
        p {
          font-size: 0.75rem;
          margin: 0;
          line-height: 1.5;
          font-size: 1rem;
          color: $lightblue;

          @media (min-width: 1920px) {
            font-size: 1rem;
            line-height: 1.5;
          }
        }
        p:nth-child(3) {
          margin-top: 50px;
        }
        .sign-up-link {
          color: $primary-color;
          text-decoration: underline;
          font-size: 1rem;
        }
      }
      .success-img {
        position: absolute;
        top: 50px;
        left: 39%;
        background-color: $white;
        border-radius: 50%;
        padding: 8px;
        transform: scale(0.65);
        @media (min-width: 1500px) {
          left: 38%;
        }
        @media (max-width: 1023px) {
          left: 36%;
        }
        @media (max-width: 767px) {
          left: 35%;
          top: 61px;
        }
        @media (max-width: 576px) {
          top: 95px;
        }
        @media (max-width: 375px) {
          left: 31%;
        }
        @media (max-width: 359px) {
          left: 28%;
          top: 90px;
        }

        img {
          border-radius: 44%;
          padding: 7px 0;
        }
      }
    }
    .payment-schedule-section {
      h3 {
        color: $darkblue;
        @media (max-width: 576px) {
          font-size: 12px;
          padding: 0px;
        }
      }
      .payment-processed {
        @media (max-width: 1024px) {
          font-size: 14px;
        }
        @media (max-width: 768px) {
          font-size: 16px;
        }
        @media (max-width: 576px) {
          font-size: 11px;
          padding: 0px;
        }
      }
    }
  }
}
//quick pay successfull payment screen//

/* Quick survey */

.quick-survey-box {
  text-align: center;
  .quick-survey-box-header {
    @include theme-aware("background", "color-background");
    padding: 16px;
    color: rgba(255, 255, 255, 0.925);
    text-align: center;
    .quick-survey-title-style {
      font-size: 1.25rem;
      font-weight: 600;
      @media (min-width: 1920px) {
        font-size: 1.625rem;
      }
      @media (max-width: 768px) {
        font-size: 1.125rem;
      }
      @media (max-width: 576px) {
        font-size: 1rem;
      }
    }
  }
  .quick-survey {
    padding: 20px;
  }
}
.feedback-emoji {
  font-size: 50px !important;
  margin: 0 30px;
  color: rgba(0, 0, 0, 0.616);
}
.feedback-emoji1:hover {
  color: $tahiti-gold;
}
.feedback-emoji2:hover {
  color: $red;
}
.satisfy-status {
  color: rgba(0, 0, 0, 0.562);
}
.feedback-emoji1-active {
  color: $tahiti-gold;
}
.feedback-emoji2-active {
  color: $red;
}
.change-skip-buttons {
  width: 15% !important;
  font-size: 30px !important;
  background: #45006c !important;
}
.survey-textarea {
  width: 100% !important;
  margin-top: 15px;
  padding: 10px;
}

.quick-survey-box-question {
  color: $primary-color;
  font-weight: 400;
  font-size: 1rem;
  span {
    @media (min-width: 1920px) {
      font-size: 18px;
    }
  }
}
.survey-submit-button {
  width: 15% !important;
  background: #45006c !important;
  font-size: 14px;
}

///error page//

.error-page-form-section {
  .page-error-content {
    padding-top: 100px;
  }
  .error-heading-style {
    font-size: 1.5rem;
    color: #000000 !important;
    font-weight: $font-medium;
  }
  .error-button-style {
    .contact-btn {
      font-size: 1.5rem;
    }
  }
}

///
