// created mixin for header design

@mixin header {
  background-image: url("../../assets/images/quick-pay-bg-strip.svg");
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 10px;
  background-position: 0px -10px;
  @media (max-width: 576px) {
    background-position: 0;
    margin-bottom: 0;
  }
  @media (max-width: 1280px) {
    margin-bottom: 0 !important;
  }
}

@mixin cardWidth($width) {
  @if $width == card-sm {
    width: 450px;
    $width-md: none;
    @media (max-width: 767px) {
      width: 500px;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  } @else if $width == card-md {
    width: 640px;
    $width-sm: none;
    @media (max-width: 767px) {
      width: 500px;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }
}
@mixin mainCard {
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  padding: 20px;
  border-radius: 8px;
  background-color: $warm-gray;
}
@mixin card {
  text-align: center;
  border-radius: 1.5rem;
  background: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@mixin logo_bg {
  background-color: $white;
  padding: 2.5rem 1.25rem;
  border-radius: 15px;
  border: 1px solid $primary-color;
  width: 145px;
  padding: 1.25em 0.938em;
  @media (max-width: 767px) {
    width: 120px;
  }
}

// Created mixin for buttons
@mixin button {
  border-radius: 6px;
  font-weight: 600;
  font-size: 0.875rem;
  outline: none;
  border: 0;
  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
}
@mixin primary_btn {
  background: $primary-color 0% 0% no-repeat padding-box;
  color: $white;
  text-transform: capitalize;
  min-width: 120px;
  padding: 10px 24px;
  @media (min-width: 1650px) {
    padding: 10px 16px;
    font-size: 1rem;
  }
  @media (max-width: 768px) {
    padding: 10px 16px;
    min-width: 100px;
  }
  @media (max-width: 576px) {
    padding: 7px;
  }
}

@mixin secondary_btn {
  background: $white 0% 0% no-repeat padding-box;
  min-width: 120px;
  text-transform: capitalize;
  padding: 0.6rem 1.5rem;
  border: 1px solid !important;
  @include theme-aware("border", "color-background");
  @include theme-aware("color", "color-background");
  padding: 9px 24px;
  @media (min-width: 1650px) {
    padding: 10px 16px;
    font-size: 1rem;
  }
  @media (max-width: 768px) {
    padding: 9px 16px;
    min-width: 100px;
  }
  @media (max-width: 576px) {
    padding: 7px;
  }
}
@mixin outlined_btn {
  background-color: $white;
  padding: 0.425em 0.625em;
  box-shadow: none !important;
  color: $primary-color;
  border: 1px solid $primary-color;
  font-weight: 400;
  text-transform: capitalize;
}
@mixin save_cancel_btn {
  padding: 0.625rem 15px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 1em;
  outline: none;
  border: 0;
}
// Created mixin for buttons
// created mixin for formcontrols

@mixin formcontrol {
  padding: 1.25rem 0;
  font-size: 0.875rem;
  margin: 0;
  outline: none;
  border-radius: 6px;
  border: none;
  font-family: poppins !important;
  @media (max-width: 1366px) {
    padding: 1.25rem 0;
  }
}

// created mixin for formcontrols
