.patient-details {
  h5 {
    text-align: left;
    padding: 0 0 18px;
    font-size: 1.25rem;
  }
  .main-container-card {
    @include mainCard;
    .radio-btns {
      .MuiIconButton-label {
        .MuiSvgIcon-root {
          fill: $darkenblue;
        }
      }
    }
    .MuiFormHelperText-root {
      font-size: 0.8rem;
    }
  }
  .futurepayment-table {
    .MuiToolbar-gutters {
      @media (max-width: 480px) {
        margin-bottom: 6px;
      }
    }
  }

  .pa-transactions {
    .MuiPaper-elevation2 {
      padding: 26px 20px 0px !important;
    }
  }
}
.transaction-card {
  background-color: $concrete-gray;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 20px 10px;
  }
  .heading-style {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    color: $primary-color !important;
    font-weight: $font-medium;
    word-break: break-all;
    text-transform: capitalize;
    @media (min-width: 601px) and (max-width: 768px) {
      font-size: 1.2rem;
      white-space: pre;
    }
    @media (max-width: 600px) {
      font-size: 1rem;
      word-break: break-word;
    }
    svg {
      font-size: 1.65rem;
      margin-right: 8px;
      color: $primary-color;
    }
  }
  .update-profile-heading {
    font-size: 1.25rem;
    margin-bottom: 15px;
    font-weight: $font-medium;
    color: $primary-color;
  }
  .profile-image-update {
    padding: 20px 0 10px;
  }
  .user-security-content {
    font-size: 0.875rem;
    @media (min-width: 1920px) {
      font-size: 1rem;
    }
  }
  .update-select-image {
    @media (max-width: 767px) {
      padding-top: 15px;
    }
  }
}
.card-images {
  @media (max-width: 576px) {
    text-align: right;
  }
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8.5px 14px !important;
  font-size: 0.8125rem !important;
}
.payment-history {
  .css-1yvcp3e-MuiSelect-root-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 8.5px 14px;
  }
}
.main-container-card {
  .payplan-table {
    .tss-1dm1iyi-MUIDataTable-responsiveScrollFullHeight {
      @media (max-width: 576px) {
        overflow-x: scroll;
      }
    }
  }
}
.user-table-data {
  .MuiTablePagination-selectLabel {
    margin-bottom: 0 !important;
  }
  .MuiTablePagination-displayedRows {
    margin-bottom: 0 !important;
  }
}
