.payment-success {
  color: $green;
}

.payment-failed {
  color: $red;
}

.payment-scheduled {
  color: $tan-green;
}
.paid-amount-colum {
  text-align: right;
}

// unused classes

.payplan-hospital {
  select {
    padding: 5px;
    margin-left: 7px;
    border-radius: 5px;
    border: 1px solid;
  }

  p {
    font-size: 15px;
    font-weight: 500;
    color: $darkgrey;

    @media (max-width: 1366px) {
      font-size: 13px;
    }

    span {
      font-weight: 600;
      color: $cool-grey;
      padding-left: 5px;
    }
  }

  .secondary-button {
    padding: 3px 15px;
    box-shadow: none !important;
    letter-spacing: 0.3px;
    font-size: 15px;

    @media (max-width: 1366px) {
      font-size: 13px;
    }

    .MuiSvgIcon-root {
      padding-right: 10px !important;
      font-size: 30px;
      color: $darkensilver;

      .MuiTablePagination-toolbar {
        .MuiSvgIcon-root {
          fill: $primary-color;
        }
      }
    }
  }

  .download-icon {
    cursor: pointer;
    color: $primary-color;
    border: 2px solid #9daa21 !important;
    margin-left: 2px;
    display: inline-block;
    margin-top: 4px;
  }

  .pay-future-align-padding {
    padding: 90px 0;
  }
}

.detail-panel-container {
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 7px;

  p {
    color: $darkgrey;
    font-size: 15px;
    font-weight: 500;
    font-family: "poppins";

    span {
      font-weight: 600;
      color: $lighten-black;
    }
  }
}

.payment-history-title {
  p {
    text-align: left;
    padding: 0 5px 0 0;
    font-size: 1.25rem;
    font-weight: 500;
    color: $primary-color;
    border-bottom: none;
  }
}

.payment-history-table {
  .MuiPaper-elevation2 {
    border: none;
    padding: 0 !important;
    box-shadow: none !important;
  }
}

.advance-search {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  .MuiButton-root {
    padding: 0px 8px;
    height: 25px;
  }

  .MuiButton-label {
    border-bottom: 1px solid $darkblue;
    color: $darkblue;
    font-size: 0.938rem !important;
    letter-spacing: 0px;
    text-transform: none;
    @media (min-width: 1920px) {
      font-size: 1.188rem !important;
    }
  }
}

.box-left-success {
  padding: 20px;
  border: 1px solid $light-orchid;
  border-radius: 4px;
  background-color: $light-orchid;
}

.heading-fail {
  font-size: 24px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;
}

.button-box {
  text-align: center;
}

.receipt-message {
  font-size: 14px;
  color: $darken-indigo;
  text-align: center;
  border: 2px dotted $flash-white;
  padding: 10px;
  border-radius: 4px;
  background-color: $flash-white;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-border:focus {
  outline: 0 !important;
}
