.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5em 0;
  background: url(../../assets/images/bg-img.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  opacity: 1;
  min-height: 100vh;
  background-blend-mode: multiply;
  background-color: rgba(0, 0, 0, 0.5);

  @media (max-width: 1023px) {
    height: auto;
    align-items: initial !important;
  }
  @media (max-width: 767px) {
    padding: 20px;
    height: auto;
  }
  @media (max-width: 1280px) {
    font-size: 0.8125rem;
  }

  .select-language {
    .MuiIconButton-label {
      background-color: #737271;
      border-radius: 15px;
      label {
        font-size: 14px;
        color: $white;
        padding: 6px 10px 0;
      }
    }
  }

  .card-sm {
    @include card;
    @include cardWidth(card-sm);
    overflow: hidden;
    .header {
      @include header;
      background-position: 0px 0px;
      min-height: 175px;
      position: relative;
      @media (max-width: 1280px) {
        background-position: 0px -10px;
      }
      @media (max-width: 576px) {
        min-height: 160px;
      }
      .rocket-icon {
        width: 152px;
        top: -27px;
        position: absolute;
        right: 10px;
        @media (max-width: 576px) {
          top: -18px;
          right: -16px;
        }
        img {
          width: 100%;
          @media (max-width: 576px) {
            width: 80%;
          }
        }
      }
      .logo {
        padding-top: 75px;
        @media (max-width: 1280px) {
          padding-top: 65px;
        }
        @media (max-width: 768px) {
          padding-top: 60px;
        }
        @media (max-width: 576px) {
          padding-top: 55px;
        }
        img {
          @include logo_bg;
        }
      }
    }
  }
  .card-md {
    @include card;
    @include cardWidth(card-md);
    overflow: hidden;
    .header {
      @include header;
      min-height: 152px;
      position: relative;
      @media (max-width: 1280px) {
        background-position: 0px -10px;
      }
      @media (max-width: 576px) {
        min-height: 150px;
      }
      .rocket-icon {
        width: 170px;
        top: -43px;
        right: 74px;
        position: absolute;
        right: 16px;
        @media (max-width: 576px) {
          width: 125px;
          top: -19px;
          right: 5px;
        }
        img {
          width: 100%;
        }
      }
      .logo {
        padding-top: 75px;
        @media (max-width: 1280px) {
          padding-top: 65px;
        }
        @media (max-width: 768px) {
          padding-top: 60px;
        }
        @media (max-width: 576px) {
          padding-top: 55px;
        }
        img {
          @include logo_bg;
        }
        &.registration-card-logo {
          padding-top: 100px;
          min-height: 203px;
          @media (min-width: 1300px) {
            min-height: 260px;
            padding-top: 140px;
          }
          @media (max-width: 576px) {
            padding-top: 60px;
            min-height: auto;
          }
        }
      }
    }
    &.registration-card {
      max-width: 550px;
    }
    &.registration-card-container {
      width: 750px;
      @media (min-width: 1300px) {
        width: 950px;
      }
      @media (max-width: 768px) {
        width: 725px;
      }
      @media (max-width: 576px) {
        width: 100%;
      }
    }
  }
}

.page-wrapper::after {
  content: "powered by Privacy Data Systems";
    color: #fff;
    bottom: 0;
    right: 40vw;
    position: absolute;
    font-size: 12px;
}
    