.echeck-container {
  font-family: $font-regular;
  .e-check-bg {
    border: 1px solid $yellow-orange;
    border-radius: 8px;
    margin: 40px 24px;
    background-color: $white-silver;

    .echeck-card {
      padding: 30px 34px;
      margin: 40px 24px;
      box-shadow: 0px 3px 13px $black;
      background-color: $white;
      border-radius: 13px;
      .form-group {
        .MuiOutlinedInput-root {
          border-radius: 8px !important;
        }
        textarea {
          font-size: 0.75rem;
          @media (min-width: 1920px) {
            font-size: 1rem;
          }
        }
        span {
          font-style: italic;
          color: $grey-web;
          font-size: 0.875rem;
        }
      }

      h6 {
        margin-bottom: 40px;
      }
      .agree-terms {
        p {
          margin: 0.625rem;
        }
        a {
          font-size: 1rem;
          font-weight: 500;
          text-decoration: underline;
        }
      }
      .pay-cancel-buttons {
        margin: 20px 0;
        text-align: right;
        button {
          padding: 8px 30px;
          margin: 0 10px;
        }
      }
    }
  }
}

/* signature-code */

.upload-sec p {
  line-height: 1 !important;
  margin-top: 40px;
}
.upload-sec .MuiDropzoneArea-root {
  min-height: 200px !important;
}
.draw-sign canvas {
  width: 445px;
}

//futurepayment

.pay-plan-details {
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0;
  border-radius: 8px;
}
.payplan-table {
  margin-top: 20px;
  .MuiPaper-root {
    border-radius: 8px !important;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px !important;
  }
  .MuiTypography-root {
    font-family: "poppins";
    @media (min-width: 768px) {
      margin-left: 6px;
    }
  }
  tbody {
    .MuiTableRow-root {
      white-space: nowrap;
    }
  }
  .MuiPaper-elevation2 {
    .MuiToolbar-gutters {
      @media (max-width: 576px) {
        padding-right: 0px;
        padding-left: 16px;
      }
    }
  }
  .MuiToolbar-gutters {
    min-height: 0 !important;
  }
  th {
    @include theme-aware("background", "lighten-1");
    white-space: nowrap;
    font-weight: 600;
    text-transform: capitalize;
    @media (min-width: 768px) {
      font-size: 1rem;
    }
  }
  @media (max-width: 576px) {
    .MuiToolbar-root {
      flex-wrap: wrap;
      justify-content: center;
      padding-left: 8px !important;
    }
  }
  .MuiTableCell-root {
    font-family: "poppins" !important;
    white-space: nowrap;
  }
  .MuiInput-root {
    margin: 10px 0;
  }
  .MuiInputBase-root {
    font-family: "poppins" !important;
    font-size: 0.875rem !important;
  }
}
.paymentplan-icons {
  @include theme-aware("color", "color-background");
  cursor: pointer;
}
.paymentplan-icons-disabled {
  color: $secondary-color;
}
.amount-box {
  background-color: $milky-gray !important;
  @include mainCard;
  box-shadow: none !important;
  margin-top: 0px;
  .paymentplan-details {
    display: flex;
    @media (max-width: 576px) {
      justify-content: space-between;
    }
    .label {
      color: $darkgrey;
      font-weight: 500;
      font-size: 14px;
      margin-right: 5px;
      white-space: nowrap;
      @media (min-width: 1920px) {
        font-size: 1.125rem;
      }
    }
    .value {
      word-break: break-all;
      color: $darkensilver;
      font-weight: 600;
      font-size: 14px;
      @media (min-width: 1920px) {
        font-size: 1.125rem;
      }
    }
  }
  .payplan-buttons {
    @media (max-width: 480px) {
      min-width: 170px !important;
      margin-top: 5px;
    }
  }
}
.paymentplan-select {
  .MuiSelect-selectMenu {
    padding: 5px 10px !important;
    min-width: 85px !important;
  }
}

@media (max-width: 1024px) {
  .upload-sec p {
    margin-top: 25px;
  }
  .billing-admin-cp .payment-plan-cp {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
@media (max-width: 768px) {
  .payment-options-detailsbtns-cp {
    text-align: center;
  }
  .upload-sec p {
    margin-top: 40px;
  }
  .draw-sign {
    width: 100% !important;
  }
  .draw-sign canvas {
    width: 340px !important;
  }
}
@media (max-width: 576px) {
  .draw-sign canvas {
    width: 300px !important;
  }
}
.cvv-icon {
  margin-bottom: 8px !important;
}
.authorization-input {
  .MuiFormControlLabel-root {
    align-items: flex-start !important;
  }
  .MuiButtonBase-root {
    padding: 0px 9px !important;
  }
  .MuiFormHelperText-root {
    position: relative !important;
    margin-left: 0px;
    font-size: 0.8rem !important;
    color: #ff0000 !important;
  }
  .form-check-label {
    text-align: left;
    .terms-btn {
      text-decoration: underline;
    }
  }
}
.echeck-header {
  text-transform: none !important;
}

.country-code {
  @media (max-width: 414px) {
    .form-control {
      .MuiOutlinedInput-input {
        padding: 0px 3px !important;
      }
    }
  }
}
.e-check-bg-card {
  .e-check-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
